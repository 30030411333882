import React, {useState, useEffect} from 'react'
import '../css/capacitacion2.css'
import {  useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
export default function Capacitacion1() {
    const [editing, setEditing] = useState (false);
    const [error, setError] = useState(''); // Para almacenar mensajes de error
    const [currentCategory, setCurrentCategory] = useState('desarrollo');
    const [calificacionDesarrollo, setCalificacionDesarrollo] = useState(0);
    const [calificacionSostenible, setCalificacionSostenible] = useState(0);
    const [calificacionHabilidades, setCalificacionHabilidades] = useState(0);
    const [porcentajeDesarrollo, setPorcentajeDesarrollo] = useState(0); 
    const [porcentajeSostenible, setPorcentajeSostenible] = useState(0); 
    const [porcentajeHabilidades, setPorcentajeHabilidades] = useState(0); 
    const [numEvidenciaDes, setPrevNumEvidenciaDes] = useState(0);
    const [numEvidenciaSos, setPrevNumEvidenciaSos] = useState(0);
    const [numEvidenciaHab, setPrevNumEvidenciaHab] = useState(0);
    
    const [desarrollo, setDesarrollo] = useState({
        desarrollo_p1: '', 
        archivo1: [],
        archivop1_des: false,                 
        desarrollo_p2: '',
        archivo2: [],
        archivop2_des: false, 
        desarrollo_p3: '', 
        archivo3: [],
        archivop3_des: false, 
        desarrollo_p4: '',
        archivo4: [],
        archivop4_des: false, 
        desarrollo_p5: '', 
        archivo5: [],
        archivop5_des: false, 
        desarrollo_p6: '',
        archivo6: [],
        archivop6_des: false, 
        fecha_registro: '',
        fecha_actualizacion: null,
        estatus: 'pendiente',     
    });
    const [sostenible, setSostenible] = useState({
        sostenible_p1: '', 
        archivo7: [],
        archivop1_sos: false,   
        sostenible_p2: '',
        archivo8: [],
        archivop2_sos: false,
        sostenible_p3: '', 
        archivo9: [],
        archivop3_sos: false,
        sostenible_p4: '',
        archivo10: [],
        archivop4_sos: false,
        sostenible_p5: '', 
        archivo11: [],
        archivop5_sos: false,
        sostenible_p6: '',
        archivo12: [],
        archivop6_sos: false,
        fecha_registro: '',
        fecha_actualizacion: null,
        estatus: 'pendiente',
    });
    const [habilidades, setHabilidades] = useState({
        habilidades_p1: '', 
        archivo13: [],
        archivop1_hab: false, 
        habilidades_p2: '',
        archivo14: [],
        archivop2_hab: false, 
        habilidades_p3: '', 
        archivo15: [],
        archivop3_hab: false, 
        habilidades_p4: '',
        archivo16: [],
        archivop4_hab: false, 
        habilidades_p5: '', 
        archivo17: [],
        archivop5_hab: false, 
        fecha_registro: '',
        fecha_actualizacion: null,
        estatus: 'pendiente',
    });

    const [fileIconColor, setFileIconColor] = useState({
        archivo1: false,
        archivo2: false,
        archivo3: false,
        archivo4: false,
        archivo5: false,
        archivo6: false,

        archivo7: false,
        archivo8: false,
        archivo9: false,
        archivo10: false,
        archivo11: false,
        archivo12: false,

        archivo13: false,
        archivo14: false,
        archivo15: false,
        archivo16: false,
        archivo17: false,
        
    });     
    
       
    const params = useParams();

    useEffect(() =>{ 
        if(params.id){
            loadTask(params.id);
            console.log("params.id: ", params.id);
        }
        
    },[params.id]);

    const loadTask = async (id) => {
        console.log("Prueba");
        //const response = await fetch(`https://apexlpz.bajaferries.com.mx/all_capacitacion_desarrollo/${id}`);
        const response = await fetch(`http://localhost:3091/all_capacitacion_desarrollo/${id}`);
        
        const data = await response.json();
        console.log("data:", data);
         
        console.log("Estatus: ", response.status);    

        if (response.ok) {
            console.log('prueba 1: Editando', data);
            setDesarrollo({
                desarrollo_p1: data.desarrollo_p1, 
                desarrollo_p2: data.desarrollo_p2,
                desarrollo_p3: data.desarrollo_p3, 
                desarrollo_p4: data.desarrollo_p4,
                desarrollo_p5: data.desarrollo_p5, 
                desarrollo_p6: data.desarrollo_p6,    
                fecha_registro: data.fecha_registro_desarrollo,    
                fecha_actualizacion: new Date().toISOString().split('T')[0],
                estatus: data.estatus_desarrollo,
                num_evidencia: data.num_evidencia_desarrollo,            
                porcent_evidencia: data.porcent_evidencia_desarrollo,
               
                archivop1_des: data.archivop1_des,
                archivop2_des: data.archivop2_des,
                archivop3_des: data.archivop3_des,
                archivop4_des: data.archivop4_des,
                archivop5_des: data.archivop5_des,
                archivop6_des: data.archivop6_des
                
            });
            
            setPrevNumEvidenciaDes(data.num_evidencia_desarrollo || 0);
            console.log("num_evidencia_desarrollo", data.num_evidencia_desarrollo || 0);
    
            setSostenible({
                sostenible_p1: data.sostenible_p1, 
                sostenible_p2: data.sostenible_p2,
                sostenible_p3: data.sostenible_p3, 
                sostenible_p4: data.sostenible_p4,
                sostenible_p5: data.sostenible_p5, 
                sostenible_p6: data.sostenible_p6,    
                fecha_registro: data.fecha_registro_sostenible,    
                fecha_actualizacion: new Date().toISOString().split('T')[0],
                estatus: data.estatus_sostenible,
                num_evidencia: data.num_evidencia_sostenible,            
                porcent_evidencia: data.porcent_evidencia_sostenible,
    
                archivop1_sos: data.archivop1_sos,
                archivop2_sos: data.archivop2_sos,
                archivop3_sos: data.archivop3_sos,
                archivop4_sos: data.archivop4_sos,
                archivop5_sos: data.archivop5_sos,
                archivop6_sos: data.archivop6_sos
            });
    
            
            setPrevNumEvidenciaSos(data.num_evidencia_sostenible || 0);
            console.log("num_evidencia_sostenible", data.num_evidencia_sostenible || 0);
    
            setHabilidades({
                habilidades_p1: data.habilidades_p1, 
                habilidades_p2: data.habilidades_p2,
                habilidades_p3: data.habilidades_p3, 
                habilidades_p4: data.habilidades_p4,
                habilidades_p5: data.habilidades_p5, 
                fecha_registro: data.fecha_registro_habilidades,  
                fecha_actualizacion: new Date().toISOString().split('T')[0],
                estatus: data.estatus_habilidades,
                num_evidencia: data.num_evidencia_habilidades,            
                porcent_evidencia: data.porcent_evidencia_habilidades,
    
                archivop1_hab: data.archivop1_sos,
                archivop2_hab: data.archivop2_sos,
                archivop3_hab: data.archivop3_sos,
                archivop4_hab: data.archivop4_sos,
                archivop5_hab: data.archivop5_sos,
            });
    
            
            setPrevNumEvidenciaHab(data.num_evidencia_habilidades || 0);
            console.log("num_evidencia_habilidades", data.num_evidencia_habilidades || 0);
    
            setFileIconColor({
                archivo1: data.archivop1_des === true,
                archivo2: data.archivop2_des === true,
                archivo3: data.archivop3_des === true,
                archivo4: data.archivop4_des === true,
                archivo5: data.archivop5_des === true,
                archivo6: data.archivop6_des === true,
    
                archivo7: data.archivop1_sos === true,
                archivo8: data.archivop2_sos === true,
                archivo9: data.archivop3_sos === true,
                archivo10: data.archivop4_sos === true,
                archivo11: data.archivop5_sos === true,
                archivo12: data.archivop6_sos === true,
    
                archivo13: data.archivop1_hab === true,
                archivo14: data.archivop2_hab === true,
                archivo15: data.archivop3_hab === true,
                archivo16: data.archivop4_hab === true,
                archivo17: data.archivop5_hab === true,
            
            });
           setEditing(true);
          
        } else {
            //setEditing(true);
            console.error('prueba 2', data.message);
        }
    }

    const handleChangeDesarrollo = (e) =>{
        setDesarrollo({...desarrollo, [e.target.name]: e.target.value});
           console.log(e.target.name, e.target.value);
    }  
    const handleChangeSostenible = async (e) => {
        setSostenible({...sostenible, [e.target.name]: e.target.value});
        console.log(e.target.name, e.target.value);
    }
    const handleChangeHabilidades = async (e) => {
        setHabilidades({...habilidades, [e.target.name]: e.target.value});
        console.log(e.target.name, e.target.value);
    }

    const navigate = useNavigate();
      
    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
        setDesarrollo((prevTask) => ({ ...prevTask, fecha_registro: currentDate }));
        setSostenible((prevTask) => ({ ...prevTask, fecha_registro: currentDate }));
        setHabilidades((prevTask) => ({ ...prevTask, fecha_registro: currentDate }));
    }, []);
/*
    useEffect(() => {
        const calculateCalificacionDesarrollo = () => {
            let score = 0;
            Object.keys(desarrollo).forEach(key => {
                /*if (key.startsWith('archivo') && desarrollo[key].length > 0) {/
                if (key.startsWith('archivo') && Array.isArray(desarrollo[key]) && desarrollo[key].length > 0) {
                    score += 1;
                }
            });

            // Si se está editando, sumar la calificación previa
            if (editing) {
                score += numEvidenciaDes;
                console.log("Editando score:", numEvidenciaDes);
                console.log("Editando score:", score);                                           
            }
            
            setCalificacionDesarrollo(score);

            const totalPreguntas = 6;
            let calculatedPorcentaje = (score * 100) / totalPreguntas;
            calculatedPorcentaje = parseFloat(calculatedPorcentaje.toFixed(2)); // Redondear a 4 cifras decimales
            setPorcentajeDesarrollo(calculatedPorcentaje); // Almacenar el porcentaje

            // Cambiar el estatus dependiendo del número de evidencias

            // Actualizar el estatus según la calificación
            let newStatus = 'Pendiente';
            if (score >= totalPreguntas) {
                newStatus = 'Finalizado'; // Cambia a Finalizado si el usuario alcanza el puntaje máximo
            } else if (score > 0 && score < totalPreguntas) {
                newStatus = 'En proceso';
            }

            // Actualizar estatus solo si es diferente al actual
           /* if (desarrollo.estatus !== newStatus) {
                setDesarrollo(prevTask => ({ ...prevTask, estatus: newStatus }));
            }/
            // Solo actualizar si los valores son diferentes para evitar el loop
        if (
            desarrollo.estatus !== newStatus || 
            desarrollo.num_evidencia !== score || 
            desarrollo.porcent_evidencia !== calculatedPorcentaje
        ) {
            setDesarrollo(prevTask => ({
                ...prevTask,
                estatus: newStatus,
                num_evidencia: score, // Número de evidencias
                porcent_evidencia: calculatedPorcentaje // Porcentaje calculado
            }));
        }

          

            };

        calculateCalificacionDesarrollo();
       
    }, [desarrollo, editing, numEvidenciaDes]);
    */

    useEffect(() => {
        const calculateCalificacionDesarrollo = () => {
            let score = 0;
    
            // Recorremos todas las claves del estado "desarrollo"
            /*
            Object.keys(desarrollo).forEach(key => {
                // Si la clave corresponde a un archivo subido
                if (key.startsWith('archivo') && Array.isArray(desarrollo[key])) {
                    // Verificar si ya existe evidencia, no volver a sumar si ya estaba
                    if (desarrollo[key].length > 0) {
                        score += 1;
                    }
                }
            });*/
            // Recorremos todas las claves del estado "desarrollo"
            Object.keys(desarrollo).forEach(key => {
                // Si la clave corresponde a un archivo subido y es un arreglo
                if (key.startsWith('archivo') && Array.isArray(desarrollo[key])) {
                    // Verificar si ya existe evidencia, no volver a sumar si ya estaba
                    if (desarrollo[key]?.length > 0) {
                        score += 1;
                    }
                }
            });

    
            // Si se está editando, mantenemos el puntaje previo
            if (editing) {
                score += numEvidenciaDes;
                console.log("Editando, sumando score previo:", numEvidenciaDes);
            }
    
            setCalificacionDesarrollo(score);
    
            const totalPreguntas = 6;
            let calculatedPorcentaje = (score * 100) / totalPreguntas;
            calculatedPorcentaje = parseFloat(calculatedPorcentaje.toFixed(2)); // Redondear
            setPorcentajeDesarrollo(calculatedPorcentaje);
    
            let newStatus = 'Pendiente';
            if (score >= totalPreguntas) {
                newStatus = 'Finalizado'; // Puntaje máximo
            } else if (score > 0 && score < totalPreguntas) {
                newStatus = 'En proceso';
            }
    
            if (desarrollo.estatus !== newStatus || 
                desarrollo.num_evidencia !== score || 
                desarrollo.porcent_evidencia !== calculatedPorcentaje
            ) {
                setDesarrollo(prevTask => ({
                    ...prevTask,
                    estatus: newStatus,
                    num_evidencia: score,
                    porcent_evidencia: calculatedPorcentaje
                }));
            }
        };
    
        calculateCalificacionDesarrollo();
    }, [desarrollo, editing, numEvidenciaDes]);
    

    

    useEffect(() => {
        const calculateCalificacionSostenible = () => {
            let score = 0;
            Object.keys(sostenible).forEach(key => {
                /*if (key.startsWith('archivo') && sostenible[key].length > 0) {*/
                   
                   
                 if (key.startsWith('archivo') && Array.isArray(sostenible[key]) && sostenible[key].length > 0) {
                 
                    score += 1;
                }
            });

              // Si se está editando, sumar la calificación previa
              if (editing) {
                score += numEvidenciaSos;
                console.log("Editando score:", numEvidenciaSos);
                console.log(" score:", score);                                           
            }

            setCalificacionSostenible(score);

            const totalPreguntas = 6;
            let calculatedPorcentaje = (score * 100) / totalPreguntas;
            calculatedPorcentaje = parseFloat(calculatedPorcentaje.toFixed(2)); // Redondear a 4 cifras decimales
            setPorcentajeSostenible(calculatedPorcentaje); // Almacenar el porcentaje
            
            /*let newStatus = 'Pendiente';
            
            if (score === 6) {
                newStatus = 'Finalizada';
            } else if (score > 0 && score < 6) {
                newStatus = 'En proceso';
            }*/

                let newStatus = 'Pendiente';
                if (score >= totalPreguntas) {
                    newStatus = 'Finalizado'; // Cambia a Finalizado si el usuario alcanza el puntaje máximo
                } else if (score > 0 && score < totalPreguntas) {
                    newStatus = 'En proceso';
                }

                if (
                    sostenible.estatus !== newStatus || 
                    sostenible.num_evidencia !== score || 
                    sostenible.porcent_evidencia !== calculatedPorcentaje
                ) {
                    setSostenible(prevTask => ({
                        ...prevTask,
                        estatus: newStatus,
                        num_evidencia: score, // Número de evidencias
                        porcent_evidencia: calculatedPorcentaje // Porcentaje calculado
                    }));
                }                
        };               

        calculateCalificacionSostenible();
       
    }, [sostenible, editing, numEvidenciaSos]);

    useEffect(() => {
        const calculateCalificacionHabilidades = () => {
            let score = 0;
            Object.keys(habilidades).forEach(key => {
              /*if (key.startsWith('archivo') && habilidades[key].length > 0) {*/
              if (key.startsWith('archivo') && Array.isArray(habilidades[key]) && habilidades[key].length > 0) {                            
                    score += 1;
                }
            });

             // Si se está editando, sumar la calificación previa
             if (editing) {
                score += numEvidenciaHab;
                console.log("Editando score:", numEvidenciaHab);
                console.log("1 score:", score);                                           
            }

            setCalificacionHabilidades(score);

            const totalPreguntas = 5;
            let calculatedPorcentaje = (score * 100) / totalPreguntas;
            calculatedPorcentaje = parseFloat(calculatedPorcentaje.toFixed(2)); // Redondear a 4 cifras decimales
            setPorcentajeHabilidades(calculatedPorcentaje); // Almacenar el porcentaje
            
           
            let newStatus = 'Pendiente';
            if (score >= totalPreguntas) {
                newStatus = 'Finalizado'; // Cambia a Finalizado si el usuario alcanza el puntaje máximo
            } else if (score > 0 && score < totalPreguntas) {
                newStatus = 'En proceso';
            }

            if (
                habilidades.estatus !== newStatus || 
                habilidades.num_evidencia !== score || 
                habilidades.porcent_evidencia !== calculatedPorcentaje
            ) {
                setHabilidades(prevTask => ({
                    ...prevTask,
                    estatus: newStatus,
                    num_evidencia: score, // Número de evidencias
                    porcent_evidencia: calculatedPorcentaje // Porcentaje calculado
                }));
            }                        
        };                 

        calculateCalificacionHabilidades();
       
    }, [habilidades, editing, numEvidenciaHab]);
    
    /* INICIO SUBIR IMAGEN */
    const uploadFiles = async (userId, name, files) => {
        const data = new FormData();
        
        console.log("userId: ",userId);

        data.append('userId', userId); // Add the user ID to the form data
        for (let i = 0; i < files.length; i++) {
          data.append(name, files[i]);
        }
    
        try {
            //const response = await axios.post('https://apexlpz.bajaferries.com.mx/api/upload', data, {
            const response = await axios.post('http://localhost:3091/api/upload', data, {
            
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('Respuesta del servidor:', response.data);
        } catch (error) {
          console.error('Error al enviar los datos:', error);
        }
    };
    
    //Suber archivos de Desarrollo
    
    const handleFileChange = async (e) => {
        const { name, files } = e.target;

        // Verificar que todos los archivos sean PDFs
        const invalidFiles = Array.from(files).some(file => file.type !== 'application/pdf');
        if (invalidFiles) {
            setError('Solo se permiten archivos PDF');
            return;
        }

        // Limpiar mensaje de error si todos los archivos son válidos
        setError('');

        // Update form data
        setDesarrollo(prevData => ({
            ...prevData,
            [name]: [...(prevData[name] || []), ...files], // Agregar archivos al arreglo existente
            [`archivop${name.slice(-1)}_des`]: true, // Actualizar el estado booleano correspondiente
        }));


        // Subir los archivos inmediatamente
        if (files.length > 0) {

            setFileIconColor(prevColor => ({
                ...prevColor,
                [name]: true, // Cambia a `true` indicando que se subió un archivo
            }));

            const userId = localStorage.getItem('userId');
            await uploadFiles(userId, name, files);
        }


    };


    const archivoMap = {
        archivo7: 'archivop1_sos',
        archivo8: 'archivop2_sos',
        archivo9: 'archivop3_sos',
        archivo10: 'archivop4_sos',
        archivo11: 'archivop5_sos',
        archivo12: 'archivop6_sos',
      };


    //Suber archivos de Sostenible
    const handleFileChangeSostenible = async (e) => {
        const { name, files } = e.target;
      
        // Verificar que todos los archivos sean PDFs
        const invalidFiles = Array.from(files).some(file => file.type !== 'application/pdf');
        if (invalidFiles) {
          setError('Solo se permiten archivos PDF');
          return;
        }
      
        setError('');
     
            setSostenible((prevData) => {
                const archivoName = name;
                const archivoSosName = archivoMap[archivoName];
                return {
                  ...prevData,
                  [archivoName]: [...(prevData[archivoName] || []), ...files],
                  [archivoSosName]: true,
                };
              });
    
            console.log("Archivo: ", [name]);
            console.log("sostenible: ", JSON.stringify(sostenible)); 

            // Subir los archivos inmediatamente
            if (files.length > 0) {
                setFileIconColor(prevColor => ({
                ...prevColor,
                [name]: true, // Cambia a `true` indicando que se subió un archivo
                }));
                
            const userId = localStorage.getItem('userId');
            await uploadFiles(userId, name, files);
        }
    };
    const archivoMapHab = {
        archivo13: 'archivop1_hab',
        archivo14: 'archivop2_hab',
        archivo15: 'archivop3_hab',
        archivo16: 'archivop4_hab',
        archivo17: 'archivop5_hab',
      };

    //Suber archivos de Habilidades
    const handleFileChangeHabilidades = async (e) => {
        const { name, files } = e.target;
      
        // Verificar que todos los archivos sean PDFs
        const invalidFiles = Array.from(files).some(file => file.type !== 'application/pdf');
        if (invalidFiles) {
          setError('Solo se permiten archivos PDF');
          return;
        }
      
        setError('');
      
        setHabilidades((prevData) => {
            const archivoName = name;
            const archivoHabName = archivoMapHab[archivoName];
            return {
              ...prevData,
              [archivoName]: [...(prevData[archivoName] || []), ...files],
              [archivoHabName]: true,
            };
          });

          console.log("Archivo: ", [name]);
        console.log("habilidades: ", JSON.stringify(habilidades)); 
      
        // Subir los archivos inmediatamente
        if (files.length > 0) {
            setFileIconColor(prevColor => ({
                ...prevColor,
                [name]: true, // Cambia a `true` indicando que se subió un archivo
            }));
            const userId = localStorage.getItem('userId');
            await uploadFiles(userId, name, files);
        }
    };

    /* FIN SUBIR IMAGEN */

    const handleSubmitDesarrollo = async (e) => {
        e.preventDefault();
  
            console.log("desarrollo: ", JSON.stringify(desarrollo)); 
           console.log("calificacionDesarrollo: ", calificacionDesarrollo);
            console.log("porcentajeDesarrollo: ", porcentajeDesarrollo);
            console.log("editing: "+ editing); 


        
            try {              
                
                if (editing) {
                    console.log("Prueba editando")
                    //const response = await fetch(`https://apexlpz.bajaferries.com.mx/capacitacion_desarrollo_update/${params.id}`, {
                   const response = await fetch(`http://localhost:3091/capacitacion_desarrollo_update/${params.id}`, {                        
                        method: 'PUT',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({...desarrollo, calificacionDesarrollo, porcentajeDesarrollo }),
                    });
                    
                    const data = await response.json();
                    console.log("Editando.." + data);
                    setCurrentCategory('sostenible');
                    
                }  else {
    
                const userId = localStorage.getItem('userId');  // Obtener el userId desde localStorage
    
                if (!userId) {
                    throw new Error("No se encontró un userId en localStorage");
                }
    
                console.log("userId: ", userId);
    
               //const res = await fetch('https://apexlpz.bajaferries.com.mx/capacitacion_desarrollo',{
                const res = await fetch('http://localhost:3091/capacitacion_desarrollo',{
                    method:'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({...desarrollo, userId, calificacionDesarrollo, porcentajeDesarrollo }),                
                });
    
                const data = await res.json();
                console.log(data);
    
                setDesarrollo({
                    desarrollo_p1: '',
                    desarrollo_p2: '',
                    desarrollo_p3: '',
                    desarrollo_p4: '',
                    desarrollo_p5: '',
                    desarrollo_p6: '',
                    fecha_registro: new Date().toISOString().split('T')[0],
                    archivos_p1: [],
                    archivos_p2: [],
                    archivos_p3: [],
                    archivos_p4: [],
                    archivos_p5: [],
                    archivos_p6: [],
                });
    
                console.log('Formulario enviado con éxito');
                
                setCurrentCategory('sostenible');
            }
            } catch (error) {
                console.error('Error al enviar el correo:', error);
            }
    };
    
    const handleSubmitSostenible = async (e) => {
        e.preventDefault();
        console.log("sostenible: ", JSON.stringify(sostenible)); 

        try {

            if(editing){
                console.log("Prueba editando")
                //const response = await fetch(`https://apexlpz.bajaferries.com.mx/capacitacion_desarrollo_update/${params.id}`, {
                const response = await fetch(`http://localhost:3091/capacitacion_sostenible_update/${params.id}`, {
                    
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({...sostenible}),
                });
                
                const data = await response.json();
                console.log("Editando.." + data);
                setCurrentCategory('habilidades');
            }else{
                const userId = localStorage.getItem('userId');  // Obtener el userId desde localStorage

                if (!userId) {
                    throw new Error("No se encontró un userId en localStorage");
                }
    
                console.log("userId: ",userId);
    
                //const res = await fetch('https://apexlpz.bajaferries.com.mx/capacitacion_sostenible',{
                const res = await fetch('http://localhost:3091/capacitacion_sostenible',{
                    method:'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({...sostenible, userId, calificacionSostenible, porcentajeSostenible}),                
                });
    
                const data = await res.json();
                console.log(data);
    
                setSostenible({
                    sostenible_p1: '', 
                    sostenible_p2: '',
                    sostenible_p3: '', 
                    sostenible_p4: '',
                    sostenible_p5: '', 
                    sostenible_p6: '',
                    fecha_registro: new Date().toISOString().split('T')[0],
                    archivos_p7: [],
                    archivos_p8: [],
                    archivos_p9: [],
                    archivos_p10: [],
                    archivos_p11: [],
                    archivos_p12: [],
                });
    
                console.log('Formulario enviado con éxito');
                setCurrentCategory('habilidades');
            }
            
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }

    }

    const handleSubmitHabilidades = async (e) => {
        e.preventDefault();
        console.log("habilidades: ", JSON.stringify(habilidades)); 
        
        try {

            if(editing){
                console.log("Prueba editando")
                //const response = await fetch(`https://apexlpz.bajaferries.com.mx/capacitacion_desarrollo_update/${params.id}`, {
                const response = await fetch(`http://localhost:3091/capacitacion_habilidades_update/${params.id}`, {                    
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({...habilidades}),
                });
                
                const data = await response.json();
                console.log("Editando.." + data);
                navigate('/dashboard'); // Redirigir al usuario a una página de destino
            }else{
                const userId = localStorage.getItem('userId');  // Obtener el userId desde localStorage

                if (!userId) {
                    throw new Error("No se encontró un userId en localStorage");
                }
    
                console.log("userId: ",userId);
    
                //const res = await fetch('https://apexlpz.bajaferries.com.mx/capacitacion_habilidades',{
                const res = await fetch('http://localhost:3091/capacitacion_habilidades',{
                    method:'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({...habilidades, userId, calificacionHabilidades, porcentajeHabilidades}),                
                });
    
                const data = await res.json();
                console.log(data);
    
                setHabilidades({
                    habilidades_p1: '', 
                    habilidades_p2: '',
                    habilidades_p3: '', 
                    habilidades_p4: '',
                    habilidades_p5: '', 
                    fecha_registro: new Date().toISOString().split('T')[0],
                    archivos_p13: [],
                    archivos_p14: [],
                    archivos_p15: [],
                    archivos_p16: [],
                    archivos_p17: [],
                    });
    
                console.log('Formulario enviado con éxito');
                navigate('/dashboard');                    
            }
           
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    }
/*
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        
        
        try {

            let evidencia_estatus = true;
            const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
            console.log("currentDate", currentDate);

            const userId = localStorage.getItem('userId');  // Obtener el userId desde localStorage

            if (!userId) {
                throw new Error("No se encontró un userId en localStorage");
            }

            console.log("userId: ",userId);

          

            //const res = await fetch('https://apexlpz.bajaferries.com.mx/evidencia',{
            const res = await fetch('http://localhost:3091/evidencia',{
                method:'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({userId, evidencia_estatus, currentDate} ),                
            });

            const data = await res.json();
            console.log(data);
            
            console.log('Formulario enviado con éxito');
            navigate('/dashboard');                    
            
           
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    }

*/
  return (
    <>
        <section className='capacitacion'>
            <div className='container'>
                <div className='capacitacion-container'>
                    <div className='capacitacion-content'>
                        <div className='ambitos'>
                            <div className='capacitacion-box-button'><button onClick={() => setCurrentCategory('desarrollo')} className={currentCategory === 'desarrollo' ? 'active' : ''} >Desarrollo Organizacional</button></div>
                            <div className='capacitacion-box-button'><button onClick={() => setCurrentCategory('sostenible')} className={currentCategory === 'sostenible' ? 'active' : ''} >Sostenible </button></div>
                            <div className='capacitacion-box-button'><button onClick={() => setCurrentCategory('habilidades')} className={currentCategory === 'habilidades' ? 'active' : ''} >Nuevas Habilidades</button></div>                        

                        </div>
                    </div>
                    <div className='capacitacion-content'>
                        {error &&(error)}
                        <div className='instrucciones'>
                            {currentCategory === 'desarrollo' && (<h2>Desarrollo organizacional</h2>)}
                            {currentCategory === 'sostenible' && (<h2>Sostenible</h2>)}
                            {currentCategory === 'habilidades' && (<h2>Nuevas habilidades</h2>)}
                            
                            <label>Llena el siguiente formulario y carga las evidencias correspondientes.</label>
                            <label><strong> Si no la tienes en este momento:</strong> selecciona la opción "No" para continuar con el formulario y subirla más adelante en el panel de administración.</label>
                            <label style={{justifyContent:'end', display:'flex', fontWeight:'500'}}>* Solo se permiten archivos en formato PDF. </label>                            
                        </div>
                        {currentCategory === 'desarrollo' &&(<>
                            <div className='preguntas-content'>
                                <div>
                                    <label></label>
                                </div>
                                <div className='criterios'>
                                    <label>SI</label>
                                    <label>NO</label>
                                    <label>Evidencia</label>
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>1. ¿La empresa cuenta programas de formación que fomenten la inclusión y diversidad en el lugar de trabajo?</label>
                                    {/*<label> (Proporciona ejemplos concretos de actividades o módulos de capacitación.)</label>*/}
                                </div>
                                <div className='criterios'>
                                    <label>                                                                              
                                        <input type="radio" name='desarrollo_p1' value="si" checked={desarrollo.desarrollo_p1 === 'si'} onChange={handleChangeDesarrollo} />                      
                                    </label>
                                    <label>                                    
                                    <input type="radio" name='desarrollo_p1' value="no" checked={desarrollo.desarrollo_p1 === 'no'} onChange={handleChangeDesarrollo} />                           
                                    </label>
                                    <label for="file-upload" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo1 ? '#8BC2A4' : '#CCCCCC' }} ></i> 
                                    </label>                                    
                                    <input type="file" id="file-upload" name="archivo1" onChange={handleFileChange} multiple accept="application/pdf" />                                    
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>2. ¿La empresa ofrece entrenamiento en habilidades de liderazgo y gestión del cambio? </label>
                                </div>
                                <div className='criterios'>
                                    <label>                                                                           
                                        <input type="radio" name='desarrollo_p2' value="si" checked={desarrollo.desarrollo_p2 === 'si'} onChange={handleChangeDesarrollo} />                         
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='desarrollo_p2' value="no" checked={desarrollo.desarrollo_p2 === 'no'} onChange={handleChangeDesarrollo} />                        
                                    </label>
                                    <label for="file-upload-dp2" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo2 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                    
                                    <input type="file" id="file-upload-dp2" name="archivo2" onChange={handleFileChange} multiple accept="application/pdf" />
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>3. ¿Se proporciona capacitación en técnicas de comunicación interna y gestión de conflictos?</label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                    <input type="radio" name='desarrollo_p3' value="si" checked={desarrollo.desarrollo_p3 === 'si'} onChange={handleChangeDesarrollo} />                        
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='desarrollo_p3' value="no" checked={desarrollo.desarrollo_p3 === 'no'} onChange={handleChangeDesarrollo} />                                                
                                    </label>
                                    <label for="file-upload-dp3" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo3 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                    
                                    <input type="file" id="file-upload-dp3" name="archivo3" onChange={handleFileChange} multiple accept="application/pdf" />
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>4. ¿La empresa tiene iniciativas de capacitación para mejorar el trabajo en equipo? </label>
                                    {/*<label>(Proporciona ejemplos de programas y sus impactos percibidos.)</label>*/}
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='desarrollo_p4' value="si" checked={desarrollo.desarrollo_p4 === 'si'} onChange={handleChangeDesarrollo} />                                                
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='desarrollo_p4' value="no" checked={desarrollo.desarrollo_p4 === 'no'} onChange={handleChangeDesarrollo} />                          
                                    </label>
                                    <label for="file-upload-dp4" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo4 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                    
                                    <input type="file" id="file-upload-dp4" name="archivo4" onChange={handleFileChange} multiple accept="application/pdf" />
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>5. ¿Se mide la eficacia de los programas de capacitación en la empresa?</label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='desarrollo_p5' value="si" checked={desarrollo.desarrollo_p5 === 'si'} onChange={handleChangeDesarrollo} />                       
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='desarrollo_p5' value="no" checked={desarrollo.desarrollo_p5 === 'no'} onChange={handleChangeDesarrollo} />                            
                                    </label>
                                    <label for="file-upload-dp5" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo5 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                    
                                    <input type="file" id="file-upload-dp5" name="archivo5" onChange={handleFileChange} multiple accept="application/pdf" />
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>6. ¿La empresa realiza evaluación de seguimiento de las capacitaciones a los colaboradores? </label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='desarrollo_p6' value="si" checked={desarrollo.desarrollo_p6 === 'si'} onChange={handleChangeDesarrollo} />                              
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='desarrollo_p6' value="no" checked={desarrollo.desarrollo_p6 === 'no'} onChange={handleChangeDesarrollo} />                   
                                    </label>                                   
                                    <label for="file-upload-dp6" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo6 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-dp6" name="archivo6" onChange={handleFileChange} multiple accept="application/pdf" />
                                </div>
                            </div>
                           
                            <div className='preguntas-content-btn'>
                                <button onClick={handleSubmitDesarrollo}>Guardar </button>
                                <button onClick={() => setCurrentCategory('sostenible')}>Siguiente </button>
                            </div>
                        </>)}

                        {currentCategory === 'sostenible' &&(<>
                            <div className='preguntas-content'>
                                <div>
                                    <label></label>
                                </div>
                                <div className='criterios'>
                                    <label>SI</label>
                                    <label>NO</label>
                                    <label>Evidencia</label>
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>1. ¿La empresa establece programas de capacitación específicos sobre prácticas sostenibles y reducción del impacto ambiental?</label>                                    
                                </div>
                                <div className='criterios'>
                                    <label>                                                                        
                                        <input type="radio" name='sostenible_p1' value="si" checked={sostenible.sostenible_p1 === 'si'} onChange={handleChangeSostenible} />                                               
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='sostenible_p1' value="no" checked={sostenible.sostenible_p1 === 'no'} onChange={handleChangeSostenible} />                                                                       
                                    </label>
                                                                
                                    <label for="file-upload-sp1" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo7 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-sp1" name="archivo7" onChange={handleFileChangeSostenible} multiple accept="application/pdf" />
                               
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>2. ¿La empresa concientiza a sus colaboradores sobre la importancia del cuidado del medio ambiente? </label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='sostenible_p2' value="si" checked={sostenible.sostenible_p2 === 'si'} onChange={handleChangeSostenible} />                                                                                              
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='sostenible_p2' value="no" checked={sostenible.sostenible_p2 === 'no'} onChange={handleChangeSostenible} />                                                                                             
                                    </label>
                                    <label for="file-upload-sp2" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo8 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-sp2" name="archivo8" onChange={handleFileChangeSostenible} multiple accept="application/pdf" />
                               
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>3. ¿La empresa cocientiza a sus colaboradores sobre la importancia del desarrollo económico sostenible?</label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='sostenible_p3' value="si" checked={sostenible.sostenible_p3 === 'si'} onChange={handleChangeSostenible} />                                                                                                                     
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='sostenible_p3' value="no" checked={sostenible.sostenible_p3 === 'no'} onChange={handleChangeSostenible} />                                                                                                                       
                                    </label>
                                    <label for="file-upload-sp3" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo9 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-sp3" name="archivo9" onChange={handleFileChangeSostenible} multiple accept="application/pdf" />
                               
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>4. ¿La empresa facilita a sus colaboradores cursos o talleres dedicados al uso eficiente  de los recursos y la gestión responsable de residuos? </label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='sostenible_p4' value="si" checked={sostenible.sostenible_p4 === 'si'} onChange={handleChangeSostenible} />                          
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='sostenible_p4' value="no" checked={sostenible.sostenible_p4 === 'no'} onChange={handleChangeSostenible} />                              
                                    </label>
                                    <label for="file-upload-sp4" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo10 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-sp4" name="archivo10" onChange={handleFileChangeSostenible} multiple accept="application/pdf" />
                               
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>5. ¿La empresa facilita la participación de sus colaboradores en programas de voluntariado, acciones comunitarias y ambientales?</label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='sostenible_p5' value="si" checked={sostenible.sostenible_p5 === 'si'} onChange={handleChangeSostenible} />                          
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='sostenible_p5' value="no" checked={sostenible.sostenible_p5 === 'no'} onChange={handleChangeSostenible} />                      
                                    </label>
                                    <label for="file-upload-sp5" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo11 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-sp5" name="archivo11" onChange={handleFileChangeSostenible} multiple accept="application/pdf" />
                               
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>6. ¿Se evalúa el conocimiento adquirido durante las capacitaciones en sostenibilidad? </label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='sostenible_p6' value="si" checked={sostenible.sostenible_p6 === 'si'} onChange={handleChangeSostenible} />                            
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='sostenible_p6' value="no" checked={sostenible.sostenible_p6 === 'no'} onChange={handleChangeSostenible} />                                                    
                                    </label>
                                    <label for="file-upload-sp6" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo12 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-sp6" name="archivo12" onChange={handleFileChangeSostenible} multiple accept="application/pdf" />
                               
                                </div>
                            </div>
                            <div className='preguntas-content-btn'>
                                <button onClick={handleSubmitSostenible}>Guardar </button>
                                <button onClick={() => setCurrentCategory('habilidades')}>Siguiente </button>
                            </div>
                        </>)}

                        {currentCategory === 'habilidades' &&(<>
                            <div className='preguntas-content'>
                                <div>
                                    <label></label>
                                </div>
                                <div className='criterios'>
                                    <label>SI</label>
                                    <label>NO</label>
                                    <label>Evidencia</label>
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>1. ¿La empresa proporciona programas de capacitación en habilidades digitales y tecnológicas? </label>                                    
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='habilidades_p1' value="si" checked={habilidades.habilidades_p1 === 'si'} onChange={handleChangeHabilidades} />                                                                                                                                                       
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='habilidades_p1' value="no" checked={habilidades.habilidades_p1 === 'no'} onChange={handleChangeHabilidades} />                                                                                                                                                                                  
                                    </label>
                                    <label for="file-upload-hp1" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo13 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-hp1" name="archivo13" onChange={handleFileChangeHabilidades} multiple accept="application/pdf" />                                                                    
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>2. ¿Existen cursos o talleres centrados en el fomento de la adaptabilidad al entorno?</label>
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='habilidades_p2' value="si" checked={habilidades.habilidades_p2 === 'si'} onChange={handleChangeHabilidades} />
                                    </label>
                                    <label>                                    
                                    <input type="radio" name='habilidades_p2' value="no" checked={habilidades.habilidades_p2 === 'no'} onChange={handleChangeHabilidades} />                           
                                    </label>
                                    <label for="file-upload-hp2" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo14 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-hp2" name="archivo14" onChange={handleFileChangeHabilidades} multiple accept="application/pdf" />                                                                    
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>3. ¿Integran las nuevas habilidades adquiridas durante la capacitación en las responsabilidades diarias de los colaboradores? </label>
                                    {/*<label>(seguimiento post-capacitación).</label>*/}
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='habilidades_p3' value="si" checked={habilidades.habilidades_p3 === 'si'} onChange={handleChangeHabilidades} />                          
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='habilidades_p3' value="no" checked={habilidades.habilidades_p3 === 'no'} onChange={handleChangeHabilidades} />                      
                                    </label>
                                    <label for="file-upload-hp3" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo15 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-hp3" name="archivo15" onChange={handleFileChangeHabilidades} multiple accept="application/pdf" />                                                                    
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>4. ¿La empresa tiene alianzas con instituciones educativas, gremios empresariales o expertos externos para proporcionar programas de capacitación especializados?  </label>                                    
                                </div>
                                <div className='criterios'>
                                    <label>                                 
                                        <input type="radio" name='habilidades_p4' value="si" checked={habilidades.habilidades_p4 === 'si'} onChange={handleChangeHabilidades} />                           
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='habilidades_p4' value="no" checked={habilidades.habilidades_p4 === 'no'} onChange={handleChangeHabilidades} />                                               
                                    </label>
                                    <label for="file-upload-hp4" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo16 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-hp4" name="archivo16" onChange={handleFileChangeHabilidades} multiple accept="application/pdf" />                                                                    
                                </div>
                            </div>
                            <div className='preguntas-content'>
                                <div className='box-pregunta'>
                                    <label>5. Han mejorado los procesos internos, atención al cliente, entre otros a partir de las capacitaciones recibidas en fomento al desarrollo de habilidades?  </label>
                                </div>
                                <div className='criterios'>
                                    <label>              
                                        <input type="radio" name='habilidades_p5' value="si" checked={habilidades.habilidades_p5 === 'si'} onChange={handleChangeHabilidades} />                                                                                          
                                    </label>
                                    <label>                                    
                                        <input type="radio" name='habilidades_p5' value="no" checked={habilidades.habilidades_p5 === 'no'} onChange={handleChangeHabilidades} />                                
                                    </label>
                                    <label for="file-upload-hp5" class="upload-button">
                                        <i class="fi fi-sr-paperclip-vertical"
                                         style={{ color: fileIconColor.archivo17 ? '#8BC2A4' : '#CCCCCC' }}></i> 
                                    </label>
                                   
                                    <input type="file" id="file-upload-hp5" name="archivo17" onChange={handleFileChangeHabilidades} multiple accept="application/pdf" />                                                                    
                                </div>
                            </div>
                           
                            <div className='preguntas-content-btn'>
                                <button onClick={handleSubmitHabilidades}>Guardar </button>
                                {/*<button onClick={handleSubmit}>Finalizar </button>*/}
                            </div>
                            
                        </>)}
                    </div>                    
                </div>
            </div>
        </section>
    </>
  )
}
