/*import React, {useEffect, useState} from 'react'
import '../css/header.css'
import { Outlet, Link } from "react-router-dom";

import logo_blanco from '../assets/images/logos/logo-blanco.png'
export default function Header() {

  const [isMobile, setIsMobile] = useState(false); // Estado para detectar si la pantalla es pequeña

  // Función para manejar el cambio de tamaño de la ventana
  const handleResize = () => {
    if (window.innerWidth <= 858) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Efecto para agregar el event listener y detectar cambios de tamaño
  useEffect(() => {
    handleResize(); // Ejecutar inicialmente para verificar el tamaño
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize); // Limpiar el listener cuando el componente se desmonte
    };
  }, []);
  

  return (
    <>
    <nav>
        <div className='container'>
        <input type="checkbox" id="check"/>
        <label for="check" class="checkbtn">
            <i class="fas fa-bars"></i>
        </label>
        <a href="/" class="enlace">
            <img src={logo_blanco} alt="logo apex" class="logo"/>
        </a>
        <ul>
            <li><Link to="/premios-apex">PREMIOS APEX</Link></li>
           {/*} <li><Link to="/comite-evaluador">COMITÉ EVALUADOR</Link></li> -/}
            <li><Link to="/aliados">ALIADOS</Link></li> 
            <li className='submenu'><Link to="/"><i class="fi fi-ss-user"></i></Link>
              <ul className='isSubmenu'>
                <li><Link to="/login">Iniciar sesión</Link></li>
                <li><Link to="/registro">Registro</Link></li>
              </ul>
            </li>  
            {isMobile && (
            <>
              <li><Link to="/login">Iniciar sesión</Link></li>
              <li><Link to="/registro">Registro</Link></li>
            </>
            )}  
                
        </ul>
        </div>
    </nav>
    <Outlet />

    </>
  )
}*/

import React, { useEffect, useState } from 'react';
import '../css/header.css';
import { Outlet, Link } from "react-router-dom";
import logo_blanco from '../assets/images/logos/logo-blanco.png';

export default function Header() {
  const [isMobile, setIsMobile] = useState(false);

  // Función para manejar el cambio de tamaño de la ventana
  const handleResize = () => {
    if (window.innerWidth <= 858) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Efecto para agregar el event listener y detectar cambios de tamaño
  useEffect(() => {
    handleResize(); // Ejecutar inicialmente para verificar el tamaño
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize); // Limpiar el listener cuando el componente se desmonte
    };
  }, []);

  // Función para cerrar el menú cuando se selecciona una opción
  const closeMenu = () => {
    const checkbox = document.getElementById('check');
    if (checkbox) {
      checkbox.checked = false; // Desmarcar el checkbox para cerrar el menú
    }
  };

  return (
    <>
      <nav>
        <div className='container'>
          <input type="checkbox" id="check" />
          <label htmlFor="check" className="checkbtn">
            <i className="fas fa-bars"></i>
          </label>
          <a href="/" className="enlace">
            <img src={logo_blanco} alt="logo apex" className="logo" />
          </a>
          <ul>
            <li><Link to="/premios-apex" onClick={closeMenu}>PREMIOS APEX</Link></li>
            {/*} <li><Link to="/comite-evaluador">COMITÉ EVALUADOR</Link></li>*/}
            <li><Link to="/aliados" onClick={closeMenu}>ALIADOS</Link></li>
            <li className='submenu'><Link to="/" onClick={closeMenu}><i className="fi fi-ss-user"></i></Link>
              <ul className='isSubmenu'>
                <li><Link to="/login" onClick={closeMenu}>Iniciar sesión</Link></li>
                <li><Link to="/registro" onClick={closeMenu}>Registro</Link></li>
              </ul>
            </li>
            {isMobile && (
              <>
                <li><Link to="/login" onClick={closeMenu}>Iniciar sesión</Link></li>
                <li><Link to="/registro" onClick={closeMenu}>Registro</Link></li>
              </>
            )}
          </ul>
        </div>
      </nav>
      <Outlet />
    </>
  );
}
