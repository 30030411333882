import React, {useState,useEffect} from 'react'
import {  useNavigate,  } from 'react-router-dom';
import '../css/diagnostico.css'

export default function Diagnostico() {
    const [alerta, setAlerta] = useState(false);
    const [task, setTask] = useState({
        tiempo_operacion: '',
        giro_comercial: '',
        total_colaboradores:'',
        ventas_anuales: '',
        discapacidad: '',
        tipo_discapacidad: '',
        alcance: '',
        fase_etapa: '',
        redes_sociales: [],
        otra_red_social: '',
        herramientas_digitales: [],
        otras_herramientas_digitales: '',
        capacitacion: '',
        ultima_capacitacion: '',
        esquema_capacitacion: '',
        inversion_anual: '',
        frecuencia_capacitacion: '',
        capacitaciones_dirigidas: '',
        temas_capacitacion: '',
        temas_interes: [],
        otro_temas_interes: '',
        barreras: '',
        otra_barreras: '',
        mision_documentada: '',
        planeacion_estrategica:'',
        rse: '',
        iniciativa_actividad:'',
        cual_iniciativa_actividad:'',
        cultura_rse: '',
        integracion_rse: '',
        ods: '',
        fecha_registro:'',
        otro_ods: ''
        
    })

    const navigate = useNavigate();
  
    const handleChange = (e) =>{
        setTask({...task, [e.target.name]: e.target.value});
        console.log(e.target.name, e.target.value);
    }  


    const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    
    setTask((prevTask) => {
        const newValues = checked
            ? [...prevTask[name], value] // Agregar el valor si está seleccionado
            : prevTask[name].filter((v) => v !== value); // Eliminar el valor si no está seleccionado
           
        return {
            ...prevTask,
            [name]: newValues,
        };

        
    });
};



useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
    setTask((prevTask) => ({ ...prevTask, fecha_registro: currentDate }));
}, []);


const isValidForm = () => {
    const requiredFields = [
        'tiempo_operacion',
        'total_colaboradores',
        'ventas_anuales',
        'discapacidad',
        'alcance',
        'fase_etapa',
        'capacitacion',
        'ultima_capacitacion',
        'esquema_capacitacion',
        'inversion_anual',
        'frecuencia_capacitacion',
        'capacitaciones_dirigidas',
        'temas_interes',
        'barreras',
    ];
    
    for (const field of requiredFields) {
      if (!task[field]) {
        return false;
      }
    }
  
    return true;
  };


const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("task: ", JSON.stringify(task)); 

    try {
                
        const userId = localStorage.getItem('userId');  // Obtener el userId desde localStorage

        if (!userId) {
            throw new Error("No se encontró un userId en localStorage");
        }

        console.log("userId: ",userId);

        //const res = await fetch('http://192.168.100.72:3091/diagnostico',{
          const res = await fetch('https://apexlpz.bajaferries.com.mx/diagnostico', {
        //const res = await fetch('http://localhost:3091/diagnostico',{
            method:'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...task, userId }),                
        });

        const data = await res.json();
        console.log(data);

       
        console.log('Formulario enviado con éxito');
        navigate('/capacitacion');

    } catch (error) {
        console.error('Error al enviar el correo:', error);
    }

}

  return (
    <>
    <section className='diagnostico'>
    <div className='container'>
            <div  className='diagnostico-container'>
                <div className='diagnostico-box'>
                    <div className='diagnostico-text'>
                        <h2>DATOS DE LA EMPRESA</h2>                    
                    </div>
                    <div className='registro-inputs'>
                        <div className='box-input-diagnostico'>
                            <label>¿Cuánto tiempo de operación tiene su empresa en la ciudad de La Paz? <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='tiempo_operacion' value="Menos de 6 meses" checked={task.tiempo_operacion === 'Menos de 6 meses'} onChange={handleChange} /> Menos de 6 meses
                                </label>
                                <label>
                                <input type="radio" name='tiempo_operacion' value="De 6 meses a 2 años" checked={task.tiempo_operacion === 'De 6 meses a 2 años'} onChange={handleChange} /> De 6 meses a 2 años 
                                </label>
                                <label>
                                <input type="radio" name='tiempo_operacion' value="Más de 2 años" checked={task.tiempo_operacion === 'Más de 2 años'} onChange={handleChange} /> Más de 2 años
                                </label>
                            </div>
                        </div> 
                        <div className='box-input-diagnostico'>
                            <label>Giro comercial (Correspondiente a la constancia de situación fiscal)</label>                            
                            <div className='box-input-diagnostico-group'>                                           
                                <textarea name='giro_comercial' value={task.giro_comercial} onChange={handleChange} />                                                                                                                
                            </div>
                        </div> 
                        <div className='box-input-diagnostico'>
                            <label>¿Cuál es el número total de colaboradores en tu empresa o negocio en La Paz?  <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>                                       
                                <textarea name='total_colaboradores' value={task.total_colaboradores} onChange={handleChange} />                                                                                                                   
                            </div>
                        </div> 
                        
                        <div className='box-input-diagnostico'>
                            <label>¿A cuánto ascienden sus ventas anuales? <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='ventas_anuales' value="De $0  hasta $ 500,000" checked={task.ventas_anuales === 'De $0  hasta $ 500,000'} onChange={handleChange} /> De $0  hasta $ 500,000
                                </label>
                                <label>
                                <input type="radio" name='ventas_anuales' value="De $500,000 hasta $1,000,000" checked={task.ventas_anuales === 'De $500,000 hasta $1,000,000'} onChange={handleChange} /> De $500,000 hasta $1,000,000 
                                </label>
                                <label>
                                <input type="radio" name='ventas_anuales' value="De $1,000,001 hasta $2,000,000" checked={task.ventas_anuales === 'De $1,000,001 hasta $2,000,000'} onChange={handleChange} /> De $1,000,001 hasta $2,000,000
                                </label>
                                <label>
                                <input type="radio" name='ventas_anuales' value="Desde $2.01 mdp hasta $4 mdp" checked={task.ventas_anuales === 'Desde $2.01 mdp hasta $4 mdp'} onChange={handleChange} /> Desde $2.01 mdp hasta $4 mdp
                                </label>
                                <label>
                                <input type="radio" name='ventas_anuales' value="Desde $4.01 mdp hasta $10 mdp" checked={task.ventas_anuales === 'Desde $4.01 mdp hasta $10 mdp'} onChange={handleChange} /> Desde $4.01 mdp hasta $10 mdp
                                </label>
                                <label>
                                <input type="radio" name='ventas_anuales' value="Mas de $10 mdp" checked={task.ventas_anuales === 'Mas de $10 mdp'} onChange={handleChange} /> Más de $10 mdp
                                </label>

                            </div>
                        </div>
                        <div className='box-input-diagnostico'>
                            <label>¿En su empresa hay personas con discapacidad?  <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='discapacidad' value="si" checked={task.discapacidad === 'si'} onChange={handleChange} /> Si
                                </label>
                                <label>
                                <input type="radio" name='discapacidad' value="no" checked={task.discapacidad === 'no'} onChange={handleChange} /> No
                                </label>                            
                            </div>
                        </div> 
                        {task.discapacidad === 'si' &&(
                            
                        <div className='box-input-diagnostico'>
                            <label>¿Qué tipo de discapacidad?  <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='tipo_discapacidad' value="Auditiva" checked={task.tipo_discapacidad === 'Auditiva'} onChange={handleChange} /> Auditiva
                                </label>
                                <label>
                                    <input type="radio" name='tipo_discapacidad' value="Visual" checked={task.tipo_discapacidad === 'Visual'} onChange={handleChange} /> Visual
                                </label> 
                                <label>
                                    <input type="radio" name='tipo_discapacidad' value="fisica_motriz" checked={task.tipo_discapacidad === 'fisica_motriz'} onChange={handleChange} /> Física (Motriz)
                                </label>
                                <label> Otra </label>
                                <div className='box-input-diagnostico-group'>
                                    <textarea placeholder='¿Cúal?' name='tipo_discapacidad' value={task.tipo_discapacidad} onChange={handleChange} />
                                </div>
                            </div>
                        </div> 

                        )}

                        <div className='box-input-diagnostico'>
                            <label>¿Cuál es el alcance comercial de su empresa o negocio? <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='alcance' value="Local" checked={task.alcance === 'Local'} onChange={handleChange} /> Local
                                </label>
                                <label>
                                <input type="radio" name='alcance' value="Estatal" checked={task.alcance === 'Estatal'} onChange={handleChange} /> Estatal
                                </label>
                                <label>
                                <input type="radio" name='alcance' value="Nacional" checked={task.alcance === 'Nacional'} onChange={handleChange} /> Nacional
                                </label>
                                <label>
                                <input type="radio" name='alcance' value="Internacional" checked={task.alcance === 'Internacional'} onChange={handleChange} /> Internacional
                                </label>                           
                            </div>
                        </div> 

                        <div className='box-input-diagnostico'>
                            <label>Fase o etapa en la que te encuentras actualmente <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='fase_etapa' value="Lanzamiento: Ya he salido al mercado y estoy validando mis productos/servicios." checked={task.fase_etapa === 'Lanzamiento: Ya he salido al mercado y estoy validando mis productos/servicios.'} onChange={handleChange} /> Lanzamiento:Ya he salido al mercado y estoy validando mis productos/servicios.
                                </label>
                                <label>
                                <input type="radio" name='fase_etapa' value="Crecimiento: Tengo la empresa en marcha y necesito aplicar nuevas estrategias de crecimiento" checked={task.fase_etapa === 'Crecimiento: Tengo la empresa en marcha y necesito aplicar nuevas estrategias de crecimiento'} onChange={handleChange} /> Crecimiento: Tengo la empresa en marcha y necesito aplicar nuevas estrategias de crecimiento
                                </label>
                                <label>
                                <input type="radio" name='fase_etapa' value="Expansión: Tengo ventas recurrentes en mi región y busco nuevos mercados" checked={task.fase_etapa === 'Expansión: Tengo ventas recurrentes en mi región y busco nuevos mercados'} onChange={handleChange} /> Expansión: Tengo ventas recurrentes en mi región y busco nuevos mercados
                                </label>                                         
                            </div>
                        </div> 

                        <div className='box-input-diagnostico'>
                            <label>¿Cuáles son las redes sociales que utiliza tu empresa o negocio? (puede seleccionar más de uno): </label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="checkbox" name='redes_sociales' value="Facebook" checked={task.redes_sociales.includes('Facebook')} onChange={handleCheckboxChange} /> Facebook
                                </label>
                                <label>
                                    <input type="checkbox" name='redes_sociales' value="Instagram" checked={task.redes_sociales.includes('Instagram')} onChange={handleCheckboxChange} /> Instagram
                                </label>
                                <label>
                                    <input type="checkbox" name='redes_sociales' value="Twitter" checked={task.redes_sociales.includes('Twitter')} onChange={handleCheckboxChange} /> Twitter
                                </label>
                                <label>
                                    <input type="checkbox" name='redes_sociales' value="Tik Tok" checked={task.redes_sociales.includes('TikTok')} onChange={handleCheckboxChange} /> Tik Tok  
                                </label>
                                <label>
                                    <input type="checkbox" name='redes_sociales' value="LindkedIn" checked={task.redes_sociales.includes('LindkedIn')} onChange={handleCheckboxChange} /> LindkedIn
                                </label>
                                <label>
                                    <input type="checkbox" name='redes_sociales' value="Whatsapp" checked={task.redes_sociales.includes('Whatsapp')} onChange={handleCheckboxChange} /> Whatsapp
                                </label>                                
                                <label>
                                    <input type="checkbox" name='redes_sociales' value="No cuento con redes sociales" checked={task.redes_sociales === 'No cuento con redes sociales'} onChange={handleChange} /> No cuento con redes sociales
                                </label>
                                <label>
                                    Otro (especifique):                                   
                                </label>
                                <div className='box-input-diagnostico-group'>
                                    <textarea name='otra_red_social' value={task.otra_red_social} onChange={handleChange} />                                                                                                                        
                                </div>                           
                            </div>
                        </div> 

                        <div className='box-input-diagnostico'>
                            <label>¿De qué manera has aprendido o aprendiste a utilizar internet y/o herramientas digitales? (puede seleccionar más de uno): (Como herramienta de capacitación) </label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="checkbox" name='herramientas_digitales' value="Cursos pagados por usted mismo" checked={task.herramientas_digitales.includes('Cursos pagados por usted mismo')} onChange={handleCheckboxChange} /> Cursos pagados por usted mismo                                    
                                </label>
                                <label>
                                    <input type="checkbox" name='herramientas_digitales' value="Cursos de su empresa" checked={task.herramientas_digitales.includes('Cursos de su empresa')} onChange={handleCheckboxChange} /> Cursos de su empresa
                                </label>
                                <label>
                                    <input type="checkbox" name='herramientas_digitales' value="Cursos gratuitos" checked={task.herramientas_digitales.includes('Cursos gratuitos')} onChange={handleCheckboxChange} /> Cursos gratuitos
                                </label>
                                <label>
                                    <input type="checkbox" name='herramientas_digitales' value="A través de amigos y/o familiares" checked={task.herramientas_digitales.includes('A través de amigos y/o familiares')} onChange={handleCheckboxChange} /> A través de amigos y/o familiares 
                                </label>
                                <label>
                                    <input type="checkbox" name='herramientas_digitales' value="En la escuela" checked={task.herramientas_digitales.includes('En la escuela')} onChange={handleCheckboxChange} /> En la escuela
                                </label>
                                <label>
                                    <input type="checkbox" name='herramientas_digitales' value="Autodidacta" checked={task.herramientas_digitales.includes('Autodidacta')} onChange={handleCheckboxChange} /> Autodidacta
                                </label>
                                <label>
                                    <input type="checkbox" name='herramientas_digitales' value="No se" checked={task.herramientas_digitales.includes('No se')} onChange={handleCheckboxChange} /> No sé  
                                </label>
                                <label>
                                    Otro (especifique):                                                                       
                                </label>
                                <div className='box-input-diagnostico-group'>
                                    <textarea name='otras_herramientas_digitales' value={task.otras_herramientas_digitales} onChange={handleChange} />                                                                                                                   
                                </div>
                            </div>
                        </div> 
                                                                    
                    </div>
                </div>
                <div className='diagnostico-box'>
                    <div className='diagnostico-text'>
                        <h2>ACERCA DE LA CAPACITACIÓN</h2>                    
                    </div>
                    <div className='registro-inputs'>    
                        <div className='box-input-diagnostico'>
                            <label>¿La empresa capacita a los colaboradores durante el año? <i>*</i> </label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='capacitacion' value="si" checked={task.capacitacion === 'si'} onChange={handleChange} /> Si
                                </label>
                                <label>
                                    <input type="radio" name='capacitacion' value="no" checked={task.capacitacion === 'no'} onChange={handleChange} /> No
                                </label>                            
                            </div>
                        </div> 

                        <div className='box-input-diagnostico'>
                            <label>¿Cuándo fue la última vez que los colaboradores recibieron capacitación? <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='ultima_capacitacion' value="Hace 6 meses" checked={task.ultima_capacitacion === 'Hace 6 meses'} onChange={handleChange} /> Hace 6 meses
                                </label>
                                <label>
                                    <input type="radio" name='ultima_capacitacion' value="Hace más de 6 meses" checked={task.ultima_capacitacion === 'Hace más de 6 meses'} onChange={handleChange} /> Hace más de 6 meses
                                </label> 
                                <label>
                                    <input type="radio" name='ultima_capacitacion' value="Hace más de 1 año" checked={task.ultima_capacitacion === 'Hace más de 1 año'} onChange={handleChange} /> Hace más de 1 año
                                </label> 
                                <label>
                                    <input type="radio" name='ultima_capacitacion' value="Hace más de 2 años" checked={task.ultima_capacitacion === 'Hace más de 2 años'} onChange={handleChange} /> Hace más de 2 años 
                                </label>                            
                            </div>
                        </div>                                                
                        <div className='box-input-diagnostico'>
                            <label>¿Qué esquema de capacitación se adapta a las necesidades de la empresa? <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='esquema_capacitacion' value="Presencial" checked={task.esquema_capacitacion === 'Presencial'} onChange={handleChange} /> Presencial 
                                </label>
                                <label>
                                    <input type="radio" name='esquema_capacitacion' value="Virtual" checked={task.esquema_capacitacion === 'Virtual'} onChange={handleChange} /> Virtual 
                                </label> 
                                <label>
                                    <input type="radio" name='esquema_capacitacion' value="Ambas" checked={task.esquema_capacitacion === 'Ambas'} onChange={handleChange} /> Ambas
                                </label> 
                                                            
                            </div>
                        </div> 
                           
                        <div className='box-input-diagnostico'>
                            <label>La inversión anual en capacitación fue de <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='inversion_anual' value="De $0 a $2,000" checked={task.inversion_anual === 'De $0 a $2,000'} onChange={handleChange} /> De $0 a $2,000
                                </label>
                                <label>
                                    <input type="radio" name='inversion_anual' value="De $2,001 a 10,000" checked={task.inversion_anual === 'De $2,001 a 10,000'} onChange={handleChange} /> De $2,001 a 10,000
                                </label>
                                <label>
                                    <input type="radio" name='inversion_anual' value="De $10,001 a $20,000" checked={task.inversion_anual === 'De $10,001 a $20,000'} onChange={handleChange} /> De $10,001 a $20,000
                                </label>
                                <label>
                                    <input type="radio" name='inversion_anual' value="$20,001 a $50,000" checked={task.inversion_anual === '$20,001 a $50,000'} onChange={handleChange} /> De $20,001 a $50,000
                                </label>
                                <label>
                                    <input type="radio" name='inversion_anual' value="$50,001 a $100,000" checked={task.inversion_anual === '$50,001 a $100,000'} onChange={handleChange} /> De $50,001 a $100,000
                                </label>
                                <label>
                                    <input type="radio" name='inversion_anual' value="mas de $100,000" checked={task.inversion_anual === 'mas de $100,000'} onChange={handleChange} /> Más de $100,000
                                </label>
                            </div>
                        </div> 
                        <div className='box-input-diagnostico'>
                            <label>¿Con que frecuencia la empresa realiza actividades de capacitación? <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='frecuencia_capacitacion' value="Al menos una vez al año" checked={task.frecuencia_capacitacion === 'Al menos una vez al año'} onChange={handleChange} /> Al menos una vez al año 
                                </label>
                                <label>
                                <input type="radio" name='frecuencia_capacitacion' value="De 2 a 4 capacitaciones al año" checked={task.frecuencia_capacitacion === 'De 2 a 4 capacitaciones al año'} onChange={handleChange} /> De 2 a 4 capacitaciones al año
                                </label>
                                <label>
                                <input type="radio" name='frecuencia_capacitacion' value="Más de 5 capacitaciones al año" checked={task.frecuencia_capacitacion === 'Más de 5 capacitaciones al año'} onChange={handleChange} /> Más de 5 capacitaciones al año 
                                </label>
                            </div>
                        </div> 
                        <div className='box-input-diagnostico'>
                            <label>Las capacitaciones que se han implementado en el último año en tu empresa estuvieron dirigidos a: <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='capacitaciones_dirigidas' value="Nivel estratégico (direcciones)" checked={task.capacitaciones_dirigidas === 'Nivel estratégico (direcciones)'} onChange={handleChange} /> Nivel estratégico (direcciones) 
                                </label>
                                <label>
                                <input type="radio" name='capacitaciones_dirigidas' value="Nivel medio (jefaturas y coordinaciones)" checked={task.capacitaciones_dirigidas === 'Nivel medio (jefaturas y coordinaciones)'} onChange={handleChange} /> Nivel medio (jefaturas y coordinaciones)
                                </label>
                                <label>
                                <input type="radio" name='capacitaciones_dirigidas' value="Nivel operativo" checked={task.capacitaciones_dirigidas === 'Nivel operativo'} onChange={handleChange} /> Nivel operativo  
                                </label>
                            </div>
                        </div> 
                        <div className='box-input-diagnostico'>
                            <label>Menciona los 3 principales temas de capacitación en el último año en la empresa <i>*</i></label>
                            <div className='box-input-diagnostico-group'>
                                <textarea name='temas_capacitacion' value={task.temas_capacitacion} onChange={handleChange}/>
                            </div>
                        </div> 
                        <div className='box-input-diagnostico'>
                            <label>De los siguientes temas, indique los 5 de interés para la empresa: <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="checkbox" name='temas_interes' value="Administración y gestión del negocio" checked={task.temas_interes.includes('Administración y gestión del negocio')} onChange={handleCheckboxChange} /> Administración y gestión del negocio
                                </label>
                                <label>
                                <input type="checkbox" name='temas_interes' value="Finanzas" checked={task.temas_interes.includes('Finanzas')} onChange={handleCheckboxChange} /> Finanzas
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Ventas" checked={task.temas_interes.includes('Ventas')} onChange={handleCheckboxChange} /> Ventas
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Pricing" checked={task.temas_interes.includes('Pricing')} onChange={handleCheckboxChange} /> Pricing
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Mercadotecnia" checked={task.temas_interes.includes('Mercadotecnia')} onChange={handleCheckboxChange} /> Mercadotecnia
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Marketing digital" checked={task.temas_interes.includes('Marketing digital')} onChange={handleCheckboxChange} /> Marketing digital
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="E-Commerce" checked={task.temas_interes.includes('E-Commerce')} onChange={handleCheckboxChange} /> E-Commerce
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Redes sociales" checked={task.temas_interes.includes('Redes sociales')} onChange={handleCheckboxChange} /> Redes sociales
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Desarrollo personal y liderazgo" checked={task.temas_interes.includes('Desarrollo personal y liderazgo')} onChange={handleCheckboxChange} /> Desarrollo personal y liderazgo
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Trabajo en equipo" checked={task.temas_interes.includes('Trabajo en equipo')} onChange={handleCheckboxChange} /> Trabajo en equipo
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Administración del personal (RRHH)" checked={task.temas_interes.includes('Administración del personal (RRHH)')} onChange={handleCheckboxChange} /> Administración del personal (RRHH)
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Asesoría legal" checked={task.temas_interes.includes('Asesoría legal')} onChange={handleCheckboxChange} /> Asesoría legal
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Logística" checked={task.temas_interes.includes('Logística')} onChange={handleCheckboxChange} /> Logística
                                </label>
                                <label>
                                    <input type="checkbox" name='temas_interes' value="Diseño de producto" checked={task.temas_interes.includes('Diseño de producto')} onChange={handleCheckboxChange} /> Diseño de producto 
                                </label>
                                <label>
                                    Otro (especifique):                                    
                                </label>
                                <div className='box-input-diagnostico-group'>
                                    <textarea name='otro_temas_interes' value={task.otro_temas_interes} onChange={handleChange}/>
                                </div>                               
                            </div>
                        </div> 

                        <div className='box-input-diagnostico'>
                            <label>¿Cuáles han sido las principales barreras a las que se ha enfrentado la empresa para implementar capacitación? <i>*</i></label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='barreras' value="Recursos económico." checked={task.barreras === 'Recursos económico.'} onChange={handleChange} /> Recursos económico.
                                </label>
                                <label>
                                <input type="radio" name='barreras' value="Tiempo para llevar a cabo la capacitación. " checked={task.barreras === 'Tiempo para llevar a cabo la capacitación. '} onChange={handleChange} /> Tiempo para llevar a cabo la capacitación. 
                                </label>
                                <label>
                                    <input type="radio" name='barreras' value="Desinterés por parte de los empleados. " checked={task.barreras === 'Desinterés por parte de los empleados. '} onChange={handleChange} /> Desinterés por parte de los empleados. 
                                </label>
                                <label>
                                    <input type="radio" name='barreras' value="Escasa oferta de capacitación en La Paz" checked={task.barreras === 'Escasa oferta de capacitación en La Paz'} onChange={handleChange} /> Escasa oferta de capacitación en La Paz
                                </label>
                                <label>
                                    <input type="radio" name='barreras' value="No estoy enterado (a) de las capacitaciones que se ofrecen en La Paz" checked={task.barreras === 'No estoy enterado (a) de las capacitaciones que se ofrecen en La Paz'} onChange={handleChange} /> No estoy enterado (a) de las capacitaciones que se ofrecen en La Paz
                                </label>
                                <label>
                                    Otro (especifique):                                    
                                </label>
                                <div className='box-input-diagnostico-group'>
                                    <textarea name='otra_barreras' value={task.otra_barreras} onChange={handleChange}/>
                                </div>   
                            </div>
                        </div> 

                    </div>

                </div>
                <div className='diagnostico-box'>
                    <div className='diagnostico-text'>
                        <h2>ESTRATEGIA EMPRESARIAL</h2>                    
                    </div>
                    <div className='registro-inputs'>
                                                                                                                    
                        <div className='box-input-diagnostico'>
                            <label>¿La empresa cuenta con misión, visión, valores documentados? </label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='mision_documentada' value="si" checked={task.mision_documentada === 'si'} onChange={handleChange} /> Si
                                </label>
                                <label>
                                    <input type="radio" name='mision_documentada' value="no" checked={task.mision_documentada === 'no'} onChange={handleChange} /> No
                                </label>                            
                            </div>
                        </div> 
                        <div className='box-input-diagnostico'>
                            <label>¿La empresa cuenta con una planeación estratégica documentada? (Objetivos, FODA, CANVAS, etc.) </label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='planeacion_estrategica' value="si" checked={task.planeacion_estrategica === 'si'} onChange={handleChange} /> Si
                                </label>
                                <label>
                                    <input type="radio" name='planeacion_estrategica' value="no" checked={task.planeacion_estrategica === 'no'} onChange={handleChange} /> No
                                </label>                            
                            </div>
                        </div> 
                                                                                                 
                    </div>
                              
                       
                </div>
                <div className='diagnostico-box'>
                    <div className='diagnostico-text'>
                        <h2>CULTURA Y RESPONSABILIDAD SOCIAL</h2>                    
                    </div>
                    <div className='registro-inputs'>
                                                                                                                    
                        <div className='box-input-diagnostico'>
                            <label>¿Qué entiende por Responsabilidad Social Empresarial? </label>                            
                            <div className='box-input-diagnostico-group'>
                                <textarea name='rse' value={task.rse} onChange={handleChange}/>                               
                            </div> 
                        </div>
                        <div className='box-input-diagnostico'>
                            <label>¿Conoce alguna iniciativa o actividad que favorezca a la comunidad de La Paz? </label>                            
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='iniciativa_actividad' value="si" checked={task.iniciativa_actividad === 'si'} onChange={handleChange} /> Si
                                </label>
                                <label>
                                    <input type="radio" name='iniciativa_actividad' value="no" checked={task.iniciativa_actividad === 'no'} onChange={handleChange} /> No
                                </label> 
                                {task.iniciativa_actividad === 'si' &&(
                                    <label>
                                        ¿Cúal?                                        
                                        <div className='box-input-diagnostico-group'>
                                            <textarea name='cual_iniciativa_actividad' value={task.cual_iniciativa_actividad} onChange={handleChange}/>
                                        </div>  
                                    </label> 
                                    
                                )}
                                                           
                            </div>
                        </div> 
                        <div className='box-input-diagnostico'>
                            <label>¿Se integra una cultura de responsabilidad social en la empresa?</label>
                            <div className='box-input-diagnostico-group'>
                                <label>
                                    <input type="radio" name='cultura_rse' value="si" checked={task.cultura_rse === 'si'} onChange={handleChange} /> Si
                                </label>
                                <label>
                                    <input type="radio" name='cultura_rse' value="no" checked={task.cultura_rse === 'no'} onChange={handleChange} /> No
                                </label>
                                {task.cultura_rse === 'si' && (
                                <>
                                    <label>¿Cómo lo integras?</label>
                                    <div className='box-input-diagnostico-group'>
                                        <textarea name='integracion_rse' value={task.integracion_rse} onChange={handleChange} />                                
                                    </div>                
                                </>
                            )}
                            </div>
                            
                        </div> 
                       

                        <div className='box-input-diagnostico'>
                            <label>¿La empresa conoce alguno de los Objetivos de Desarrollo Sostenible (ODS)? </label>
                            <div className='box-input-diagnostico-group'>
                                <label>
                                <input type="radio" name='ods' value="si" checked={task.ods === 'si'} onChange={handleChange} /> Si, ¿Cuáles?:                                    
                                </label>
                                {task.ods === 'si' &&( <div className='box-input-diagnostico-group'>
                                    <label>                                   
                                        <textarea name='otro_ods' value={task.otro_ods} onChange={handleChange} />
                                    </label>
                                </div>   )}

                                <label>
                                    <input type="radio" name='ods' value="no" checked={task.ods === 'no'} onChange={handleChange} /> No
                                </label>                            
                            </div>
                        </div> 
                            
                        <div className='box-button'>                                                     
                           
                            {alerta && (
                                <>
                                    <label className='alertaMensaje'>Por favor, completa todos los campos obligatorios </label> <br />
                                </>
                            )}
                            <button onClick={(e) => { if (isValidForm()) {  handleSubmit(e);} else { setAlerta(true) }  }}>Enviar </button>                                                                                         
                        </div>                                                   
                    </div>
                           
                       
                </div>
                     
            </div>           
        </div>
    </section>    
    </>
  )
}
