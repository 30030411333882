import React, { useState } from 'react'
import '../css/premios.css'
import { CSSTransition } from "react-transition-group";
import reconocer from '../assets/images/premios/reconocer.webp'
import about_reconocimiento from '../assets/images/premios/about-reconocimiento.webp'
import Aliados from '../components/Aliados';
import Footer from '../components/Footer'


export default function Premios() {
    const FAQ = ({ question, answer }) => {
        const [showAnswer, setShowAnswer] = useState(false);
      
        const toggleAnswer = () => {
          setShowAnswer(!showAnswer);
        };
      
        return (
          <div className="faq-item">
            <div className="question" onClick={toggleAnswer}>
              <div className='box-question-box'><label>{question}</label> <label> <i className={showAnswer ? "fi fi-bs-cross" : "fi fi-bs-plus"}></i></label></div>
            </div>
            <CSSTransition in={showAnswer} timeout={300} classNames="answer" unmountOnExit>
              <div className="answer">{answer}</div>
            </CSSTransition>
          </div>
        );
      };

    const faqs = [
        {          
          question: "¿Qué son los premios APEX?",
          answer: "Los premios APEX La Paz están dedicados a reconocer y celebrar los esfuerzos sobresalientes por la generación del aprendizaje a lo largo de la vida dentro del sector empresarial. Por lo tanto, nos enfocamos en identificar y honrar a las MiPYMES y ONG’s que contribuyen al desarrollo personal y profesional de sus colaboradores. ", },
        {
            question: "¿Cuál es el objetivo?",
            answer: "Nuestro objetivo principal es convertir el distintivo APEX La Paz en el referente por excelencia para aquellas organizaciones comprometidas con el desarrollo profesional y personal de sus colaboradores a través de la constante capacitación y el fomento al aprendizaje continuo. Buscamos inspirar un compromiso con la innovación y la calidad laboral en el ecosistema empresarial del municipio de La Paz.",
        },
        {
          question: "¿Quiénes pueden ser reconocidos y comprometidos?",
          answer: "Micro, Pequeñas y Medianas Empresas (MiPyMES) y Organizaciones de la Sociedad Civil (ONG´s) que radiquen en La Paz, Baja California Sur y que estén legalmente constituidas.",
        },
        {
            question: "¿Qué reciben los ganadores?",
            answer: <>
            <label>Los premiados con el distintivo APEX La Paz reciben la insignia y reconocimiento que simboliza su compromiso excepcional con la formación y el desarrollo profesional y personal de sus colaboradores, además de algunos estímulos en especie para incentivar y dar las herramientas para una constante capacitación y desarrollo de habilidades de los empleados.</label>
            <label> Adicionalmente, a nivel de medios de comunicación, su labor y contribución se destacan, mejorando su posicionamiento y reputación frente a otras organizaciones y los proyecta como referentes en la promoción de mejores practicas socialmente responsables y sostenibles. </label>
            </>,
        },
        {
            question: "¿Por qué damos una estatuilla?",
            answer: "Resulta crucial destacar a las organizaciones comprometidas con el desarrollo de la fuerza laboral, para así crear comunidades mas empoderadas, cohesionada, prospera económica y culturalmente y con énfasis en el desarrollo sostenible.",
        },
        {
            question: "¿Qué criterios de evaluación seguimos?",
            answer: "Que la evidencia compartida refleje el compromiso de la organización con el desarrollo de sus colaboradores, la calidad y alcance de sus programas así como el impacto en el desempeño laboral.",
        },
        {
            question: "¿Cuándo y dónde se celebran los premios APEX?",
            answer: "La ceremonia de entrega y reconocimiento del Distintivo APEX se lleva a cabo en el mes de noviembre año con año.",
        }
        ,
        {
            question: "¿Dónde se celebran los premios APEX La Paz?",
            answer: "La ubicación de la sede puede variar, pero siempre dentro del municipio de La Paz, en Baja California Sur.",
        }
        
        
      ];

    

  return (
    <>
    <section className='premios_hero'>
            <div className='container'>
               <div className='hero_container_premios '>
                <div className='hero_content_premios'>
                       
                </div>
               </div>
            </div>
    </section>

     <section className='bases'>
        <div className='container'>
            <div className='bases_container'>
                <div className='bases_content'>
                    <img src={reconocer} alt='bases de participacion apex' />
                </div>
                <div className='bases_content'>
                    
                        <h2><i style={{fontStyle:'italic'}}>APEX</i> te reconoce </h2>
                        <p>El compromiso y esfuerzo de las Micro,
                            Pequeñas y Medianas Empresas <strong>(MiPyMES)</strong> y
                            Organizaciones de la Sociedad Civil <strong>(ONG’s)</strong> con
                            el desarrollo profesional y personal de sus
                            colaboradores a través de <strong> programas de
                            capacitación sostenida</strong> y el fomento al
                            aprendizaje continuo entre sus colaboradores</p>
                    
                    <div className='line-premios'>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='categoria'>
        <div className='container'>
            <div className='categoria_container'>
                <div className='categoria_title'>
                    <h2>categorías</h2>
                    

                </div>
                <div className='categorias_content'>
                    <div className='categoria_content'>
                        <h3>micro</h3>
                        <div className='categorias_box'>
                            <label>&lt; 10</label>
                            <span></span>
                            <label>Empleados</label>
                        </div>
                    </div>
                    <div className='categoria_content'>
                        <h3>pequeña</h3>
                        <div className='categorias_box'>
                            <label>11-50</label>
                            <span></span>
                            <label>Empleados</label>
                        </div>
                    </div>
                    <div className='categoria_content'>
                        <h3>mediana</h3>
                        <div className='categorias_box'>
                            <label>50-250</label>
                            <span></span>
                            <label>Empleados</label>
                        </div>
                    </div>
                </div>
                <div className='reconocimiento_content'>
                    <div className='reconocimiento_box'>
                        <h4>MiPyMES</h4>
                        <p>reconocimiento diferenciado por sector</p>
                        <ul>
                            <li>• <strong>Primario:</strong> Agricultura, Ganadería, pesca, acuacultura, entre otros.</li>
                            <li>• <strong>Secundario:</strong> Industrial (Empresas dedicadas a la manufactura o construcción, transformación de bienes)</li>
                            <li>• <strong>Terciario:</strong> Sector Servicios (comercio, transporte, servicios financieros, servicios sociales)</li>
                        </ul>
                    </div>
                    <div className='reconocimiento_box'>
                        <h4>ONG’s</h4>
                        <p>reconocimiento diferenciado objeto social</p>
                        <ul>                            
                            <li>• Innovación y educación</li>
                            <li>• Sostenibilidad y medio ambiente</li>                            
                        </ul>
                    </div>
                </div>
                <div className='nota'>
                    <p>El Consejo Evaluador podrá reclasificar a las organizaciones en otra categoría de participación a sugerencia, cuando su actividad o estructura así lo amerite. En tal caso, la organización participante será notificada sobre esta modificación y su fundamento.</p>
                </div>
            </div>

        </div>
        
    </section>
    <section className='premios'>
        <div className=''>
            <div className='premios_reconociemitno_container'>
                <div className='premios_reconociemitno_img'>
                    <img src={about_reconocimiento} alt='reconocimiento apex'/>
                </div>
                <div className='premios_reconociemitno_faq'>
                    <div>
                        <h2>Acerca del reconocimiento</h2>
                        <div className="preguntas_frecuentes_apex">
                    
                            {faqs.map((faq, index) => (
                                <FAQ key={index} question={faq.question} answer={faq.answer} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </section>
   
    
    <Aliados />
    <Footer />

   
    </>
  )
}
