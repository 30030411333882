import React from 'react'
import '../css/eliminar.css'
import logo from '../assets/images/apex-logo.png'
export default function Eliminar() {
  return (
    <section className='correo'>
        <div className='container'>
            <div className='body_content'>
                <div className='header_correo'>
                    <div>
                        <img src={logo} alt='' />
                    </div>
                    <p>Acreditación para Pequeñas Empresas con Excelencia en Aprendizaje</p>
                </div>
         
                    <h2>¡Bienvenido a Apex!</h2>
  
                <div>
                    <p>Nos alegra darte la bienvenida a Apex, acreditación para empresas con excelencia en aprendizaje.</p>
                    <p>Agradecemos tu interés y te invitamos a completar tu registro </p>
                    <p>Te recomendamos leer nuestro aviso de privacidad, para que conozcas todos los detalles sobre el uso de la plataforma.</p>
                </div>
                <div>
                    <ul>
                        <li><a href="http://apexlpz.bajaferries.com.mx/aviso-de-privacidad">Aviso de Privacidad</a></li>
                        <li><a href="http://apexlpz.bajaferries.com.mx/Términos y condiciones">Términos y condiciones</a></li>
                    </ul>
                </div>
                <div className='footer_correo'>
                    <p>© 2024 ApexLPZ. Todos los derechos reservados.</p>
                </div>
            </div>
        </div>
    </section>
  )
}
