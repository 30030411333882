import React from 'react'
import '../css/aliados.css'
import logo1 from '../assets/images/aliados/ConsejoLaPaz.png'
import logo2 from '../assets/images/aliados/BajaFerries.png'
import logo3 from '../assets/images/aliados/Red de Ciudades.png'
import logo4 from '../assets/images/aliados/LaPaz.png'
import logo5 from '../assets/images/aliados/CIB nor.png'
import logo6 from '../assets/images/aliados/canaco.png'
import logo8 from '../assets/images/aliados/CCE.png'

export default function Aliados() {
  return (
    <section className='aliados-container'>
        <div className='container'>
            <div className='aliados_content'>
                <div className='box_aliados'> <img src={logo1} alt='' /></div>
                <div className='box_aliados'> <img src={logo2} alt='' /></div>
                <div className='box_aliados'> <img src={logo3} alt='' /></div>
                <div className='box_aliados'> <img src={logo4} alt='' /></div>

                <div className='box_aliados'> <img src={logo5} alt='' /></div>
                <div className='box_aliados'> <img src={logo6} alt='' /></div>
                <div className='box_aliados'> <img src={logo8} alt='' /></div>
            </div>         
        </div>
    </section>
  )
}
