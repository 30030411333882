import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import '../css/navbar.css'

export default function Navbar() {

  const [scroll, setScroll] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20; // Cambia este valor según tus necesidades
      if (isScrolled !== scroll) {
        setScroll(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, [scroll]);

    const toggleMenu = () => {
        setIsMenu(!isMenu);
    };

    const toggleShowMenu = () => {
        setShowMenu(!showMenu)

    }



  return (
    <>
  
       <nav className={scroll ? 'menucarga scrolled' : 'menucarga'}>
       
        <div className='container'>
            <div className='nav-menu-carga'>
                <div className='logo_carga'>
                    <Link to="/dashboard" className="menu-item" >
                        <div className='menu_img_show_carga'></div>
                    </Link>
                </div>
                <div className='menu_medio'>
                 
                   
                </div>
                <div className='menu_derecho'>
                    <ul>                        
                        <li onClick={toggleShowMenu}>Usuario 
                       {showMenu && (
                        <ul className='submenu-box'>
                            <li><i class="fi fi-ss-exit"></i> <label>Cerrar sesión</label></li>
                        </ul>  
                       )}   
                       </li>                   
                    </ul>
                </div>                
            </div>            
        </div>               
     </nav>
     <nav className='menu_responsive_carga'>
        <div className='container'>
            <div className='menu_responsive_carga_content'>
                <div>
                    <Link to="/"><div class="menu_img_show_carga"></div></Link>
                </div>
                <div className='btn_menu_responsive_carga'>
                    <button onClick={toggleMenu}><i class="fi fi-rr-menu-burger"></i></button>
                </div>
            </div>            
        </div>        
     </nav>
     {isMenu &&(
                <div className='menu_carga_activo'>
                   
                   <div className='container'>
                   <div className='menu_responsive_carga_content'>
                        <div>
                            <Link href="/"><div class="menu_img_show_carga"></div></Link>
                        </div>
                        <div className='btn_menu_responsive_carga'>
                            <button onClick={toggleMenu}><i class="fi fi-br-cross"></i></button>
                        </div>
                    </div>
                  
                   <div className='submenu_list_carga'>
                        <ul>
                       
                            
                            <li><Link to='/maritimo'>Marítimo </Link>
                           
                            </li>
                            
                            <li><Link to='/terrestre' >Terrestre</Link>
                          
                            </li>
                            
                            
                           
                            <li><Link to='/integracion-logistica'>Integración logística </Link></li>
                         
                            
                        </ul>
                   </div>
                  

                   </div>
                </div>                
            )}
     <Outlet />
    
    </>
  )
}
