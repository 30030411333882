import React, {useState,useEffect} from 'react'
import '../css/registro.completo.css'
import {  useNavigate,  } from 'react-router-dom';
export default function RegistroCompleto() {
    const [alerta, setAlerta] = useState(false);
    const [regimenFiscal, setRegimenFiscal] = useState('moral');
    const [domicilioFiscal, setDomicilioFiscal] = useState('mismoDom');  
    const [domicilioFiscalFisica, setDomicilioFiscalFisica] = useState('mismoDomFiscal');  
    const [colonias, setColonias] = useState([]);  
    const [colonias1, setColonias1] = useState([]);  
    const [colonias2, setColonias2] = useState([]);  
    const [colonias3, setColonias3] = useState([]);  
    const [fisica, setFisica] = useState({
        nombre_comercial: '',
        nombre_completo: '',
        rfc: '',
        inicio_operaciones: '',
        sector: '',
        producto_servicio: '',
        fecha_nacimiento: '',
        curp: '',
        entidad_nacimiento: '',
        sexo: '',
        puesto: '',
        email: '',
        telefono: '',
        pagina_web: '',
        comercial_calle: '',
        comercial_numero_ext: '',
        comercial_numero_int: '',
        comercial_codigo_postal: '',
        comercial_colonia: '',
        comercial_ciudad: '',
        comercial_estado: '',
        comercial_delegacion_municipio: '',
        comercial_pais: '',
        fiscal_calle: '',
        fiscal_numero_ext: '',
        fiscal_numero_int: '',
        fiscal_codigo_postal: '',
        fiscal_colonia: '',
        fiscal_ciudad: '',
        fiscal_estado: '',
        fiscal_delegacion_municipio: '',
        fiscal_pais: '',
        fecha_registro: '',
        status: '',   
        });

    const [moral, setMoral] = useState({
        nombre_comercial: '',
        razon_social: '',
        rfc: '',
        inicio_operaciones: '',
        sector: '',
        producto_servicio: '',   
        sexo: '',
        puesto: '',
        telefono: '',
        email: '',
        pagina_web: '',
        comercial_calle: '',
        comercial_numero_ext: '',
        comercial_numero_int: '',
        comercial_codigo_postal: '',
        comercial_colonia: '',
        comercial_ciudad: '',
        comercial_estado: '',
        comercial_delegacion_municipio: '',
        comercial_pais: '',
        fiscal_calle: '',
        fiscal_numero_ext: '',
        fiscal_numero_int: '',
        fiscal_codigo_postal: '',
        fiscal_colonia: '',
        fiscal_ciudad: '',
        fiscal_estado: '',
        fiscal_delegacion_municipio: '',
        fiscal_pais: '',
        fecha_registro: '',
        status: null,   
    })

    const handleChangeComercialMoral = async (e) => {

        const { name, value } = e.target;

        setMoral((prevMoral) => ({
            ...prevMoral,
            [name]: value
        }));


        if (value.length === 5) {
            await obtenerDatosComercialMoral(value);
        }
    };

    const obtenerDatosComercialMoral = async (cp) => {

        console.log("codigoPostal 1: ", cp);

        try {
        
            const response = await fetch(`https://api.copomex.com/query/info_cp/${cp}?token=2c1a684b-400b-4e1b-ad60-8ea2b6748222`);
            if (!response.ok) {
            throw new Error('No se pudieron obtener los datos de la dirección');
            }

            const data = await response.json();
            console.log("data: ", data);

            // Extraer las colonias de los resultados
            const coloniasObtenidas = data.map(item => item.response.asentamiento);
            setColonias(coloniasObtenidas); 

            setMoral((prevMoral) => ({
                ...prevMoral,
                comercial_ciudad: data[0].response.ciudad || '',
                comercial_estado: data[0].response.estado || '',
                comercial_delegacion_municipio: data[0].response.municipio || '',
                comercial_pais: data.comercial_pais || 'México',
            }));
            console.log("Moral: ", moral);

        
        } catch (error) {
            console.error(error);
            // Manejo de errores, por ejemplo, mostrar un mensaje al usuario
        }
        
    };

    const handleChangeFiscalMoral = async (e) => {

        const { name, value } = e.target;
       
        setMoral((prevMoral) => ({
            ...prevMoral,
            [name]: value
        }));


        if (value.length === 5) {
            await obtenerDatosFiscalMoral(value);
        }
    };

    const obtenerDatosFiscalMoral = async (cp) => {

        console.log("codigoPostal: 2", cp);

        try {
        
            const response = await fetch(`https://api.copomex.com/query/info_cp/${cp}?token=2c1a684b-400b-4e1b-ad60-8ea2b6748222`);
            if (!response.ok) {
            throw new Error('No se pudieron obtener los datos de la dirección');
            }

            const data = await response.json();
            console.log("data: ", data);

            // Extraer las colonias de los resultados
            const coloniasObtenidas = data.map(item => item.response.asentamiento);
            setColonias1(coloniasObtenidas); 
            
            setMoral((prevMoral) => ({
                ...prevMoral,
                fiscal_ciudad: data[0].response.ciudad || '',
                fiscal_estado: data[0].response.estado || '',
                fiscal_delegacion_municipio: data[0].response.municipio || '',
                fiscal_pais: data.comercial_pais || 'México',
            }));
            console.log("Moral: ", moral);

        
        } catch (error) {
            console.error(error);
            // Manejo de errores, por ejemplo, mostrar un mensaje al usuario
        }
        
    };

    const handleChangeComercialFisica = async (e) => {

        const { name, value } = e.target;
        setFisica((prevFisica) => ({
            ...prevFisica,
            [name]: value
        }));

     

        if (value.length === 5) {
            await obtenerDatosComercialFisica(value);
        }
    };

    const obtenerDatosComercialFisica = async (cp) => {

        console.log("codigoPostal: 3", cp);

        try {
        
            const response = await fetch(`https://api.copomex.com/query/info_cp/${cp}?token=2c1a684b-400b-4e1b-ad60-8ea2b6748222`);
            if (!response.ok) {
            throw new Error('No se pudieron obtener los datos de la dirección');
            }

            const data = await response.json();
            console.log("data: ", data);

            // Extraer las colonias de los resultados
            const coloniasObtenidas = data.map(item => item.response.asentamiento);
            setColonias2(coloniasObtenidas); 

            setFisica((prevFisica) => ({
                ...prevFisica,
                comercial_ciudad: data[0].response.ciudad || '',
                comercial_estado: data[0].response.estado || '',
                comercial_delegacion_municipio: data[0].response.municipio || '',
                comercial_pais: data.fiscal_pais || 'México',
            }));
            console.log("Fisica: ", fisica);
        
        } catch (error) {
            console.error(error);
            // Manejo de errores, por ejemplo, mostrar un mensaje al usuario
        }
        
    };
    
    const handleChangeFiscalFisica = async (e) => {

        const { name, value } = e.target;
        setFisica((prevFisica) => ({
            ...prevFisica,
            [name]: value
        }));

        setMoral((prevMoral) => ({
            ...prevMoral,
            [name]: value
        }));


        if (value.length === 5) {
            await obtenerDatosFiscalFisica(value);
        }
    };
    
    const obtenerDatosFiscalFisica = async (cp) => {

        console.log("codigoPostal: 4", cp);

        try {
        
            const response = await fetch(`https://api.copomex.com/query/info_cp/${cp}?token=2c1a684b-400b-4e1b-ad60-8ea2b6748222`);
            if (!response.ok) {
            throw new Error('No se pudieron obtener los datos de la dirección');
            }

            const data = await response.json();
            console.log("data: ", data);

            // Extraer las colonias de los resultados
            const coloniasObtenidas = data.map(item => item.response.asentamiento);
            setColonias3(coloniasObtenidas); 

            setFisica((prevFisica) => ({
                ...prevFisica,
                fiscal_ciudad: data[0].response.ciudad || '',
                fiscal_estado: data[0].response.estado || '',
                fiscal_delegacion_municipio: data[0].response.municipio || '',
                fiscal_pais: data.fiscal_pais || 'México',
            }));
            console.log("Fisica: ", fisica);

            
        } catch (error) {
            console.error(error);
            // Manejo de errores, por ejemplo, mostrar un mensaje al usuario
        }
        
    };

    useEffect(() => {
        // Si el usuario selecciona "mismoDom", copiar los datos comerciales al domicilio fiscal
        if (domicilioFiscal === 'mismoDom') {
            setMoral((prevMoral) => ({
                ...prevMoral,
                fiscal_calle: prevMoral.comercial_calle,
                fiscal_numero_ext: prevMoral.comercial_numero_ext,
                fiscal_numero_int: prevMoral.comercial_numero_int,
                fiscal_codigo_postal: prevMoral.comercial_codigo_postal,
                fiscal_colonia: prevMoral.comercial_colonia,
                fiscal_ciudad: prevMoral.comercial_ciudad,
                fiscal_estado: prevMoral.comercial_estado,
                fiscal_delegacion_municipio: prevMoral.comercial_delegacion_municipio,
                fiscal_pais: prevMoral.comercial_pais,
            }));

            
        }
    }, [domicilioFiscal, moral.comercial_calle, moral.comercial_numero_ext, moral.comercial_numero_int, moral.comercial_codigo_postal, moral.comercial_colonia, moral.comercial_ciudad, moral.comercial_estado, 
        moral.comercial_delegacion_municipio, moral.comercial_pais]);

        useEffect(() => {
            // Si el usuario selecciona "mismoDom", copiar los datos comerciales al domicilio fiscal
            if (domicilioFiscalFisica === 'mismoDomFiscal') {
                setFisica((prevFisica) => ({
                    ...prevFisica,
                    fiscal_calle: prevFisica.comercial_calle,
                    fiscal_numero_ext: prevFisica.comercial_numero_ext,
                    fiscal_numero_int: prevFisica.comercial_numero_int,
                    fiscal_codigo_postal: prevFisica.comercial_codigo_postal,
                    fiscal_colonia: prevFisica.comercial_colonia,
                    fiscal_ciudad: prevFisica.comercial_ciudad,
                    fiscal_estado: prevFisica.comercial_estado,
                    fiscal_delegacion_municipio: prevFisica.comercial_delegacion_municipio,
                    fiscal_pais: prevFisica.comercial_pais,
                }));
    
                
            }
        }, [domicilioFiscalFisica, fisica.comercial_calle, fisica.comercial_numero_ext, fisica.comercial_numero_int, fisica.comercial_codigo_postal, fisica.comercial_colonia, fisica.comercial_ciudad, fisica.comercial_estado, 
            fisica.comercial_delegacion_municipio, fisica.comercial_pais]);


    const handleOptionChange = (event) => {
        setRegimenFiscal(event.target.value);
    };

    const handleDomicilioChange = (event) => {
        setDomicilioFiscal(event.target.value);
        setDomicilioFiscalFisica(event.target.value);
    }
    const navigate = useNavigate();

    const handleChange = (e) =>{
        setFisica({...fisica, [e.target.name]: e.target.value});
        console.log(e.target.name, e.target.value);

        setMoral({...moral, [e.target.name]: e.target.value});
        console.log(e.target.name, e.target.value);
    
    }  

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
        setFisica((prevfisica) => ({ ...prevfisica, fecha_registro: currentDate }));
        setMoral((prevmoral) => ({ ...prevmoral, fecha_registro: currentDate }));
    }, []);

        //Validacion form Moral
    const isValidForm = () => {
        const requiredFields = [
          'nombre_comercial',
          'razon_social',
          'sector',
          'producto_servicio',
          'sexo',
          'puesto',
          'email',
          'comercial_codigo_postal',
          'comercial_colonia',
          'comercial_ciudad',
          'comercial_estado',
          'comercial_delegacion_municipio',
          'comercial_pais',
        ];
      
        if (domicilioFiscal === 'otroDom') {
          requiredFields.push(
            'fiscal_codigo_postal',
            'fiscal_colonia',
            'fiscal_ciudad',
            'fiscal_estado',
            'fiscal_delegacion_municipio',
            'fiscal_pais',
          );
        }
      
        for (const field of requiredFields) {
          if (!moral[field]) {
            return false;
          }
        }
      
        return true;
      };

        //Validacion form Fisica
      const isValidFormFisica = () => {
        const requiredFields = [
            'nombre_comercial',
            'nombre_completo',
            'rfc',
            'sector',
            'producto_servicio',
            'fecha_nacimiento',
            'curp',
            'entidad_nacimiento',
            'sexo',
            'puesto',
            'email',
            'telefono',
            'sexo',
            'puesto',
            'email',
            'comercial_codigo_postal',
            'comercial_colonia',
            'comercial_ciudad',
            'comercial_estado',
            'comercial_delegacion_municipio',
            'comercial_pais',
        ];
      
        if (domicilioFiscal === 'otroDom') {
          requiredFields.push(
            'fiscal_codigo_postal',
            'fiscal_colonia',
            'fiscal_ciudad',
            'fiscal_estado',
            'fiscal_delegacion_municipio',
            'fiscal_pais',
          );
        }
      
        for (const field of requiredFields) {
          if (!fisica[field]) {
            return false;
          }
        }
      
        return true;
      };


    const handleSubmitMoral = async (e) => {
        e.preventDefault();
        console.log("moral: ", JSON.stringify(moral)); 

        try {

            const userId = localStorage.getItem('userId');  // Obtener el userId desde localStorage

            if (!userId) {
                throw new Error("No se encontró un userId en localStorage");
            }

            console.log("userId: ",userId);

           //const res = await fetch('http://192.168.100.72:3091/persona_moral',{
            const res = await fetch('https://apexlpz.bajaferries.com.mx/persona_moral', {
            // const res = await fetch('http://localhost:3091/persona_moral',{
                method:'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({...moral, userId }),   
                //body: JSON.stringify(fisica),                 
            });

            const data = await res.json();
            console.log(data);

        
            console.log('Formulario enviado con éxito');
            navigate('/diagnostico');

        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
            
    }

    const handleSubmitFisica = async (e) => {
        e.preventDefault();
        console.log("fisica: ", JSON.stringify(fisica)); 

        try {

            const userId = localStorage.getItem('userId');  // Obtener el userId desde localStorage

            if (!userId) {
                throw new Error("No se encontró un userId en localStorage");
            }

            console.log("userId: ",userId);

            //const res = await fetch('http://192.168.100.72:3091/persona_fisica',{
                const res = await fetch('https://apexlpz.bajaferries.com.mx/persona_fisica', {
            //  const res = await fetch('http://localhost:3091/persona_fisica',{
                
                method:'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({...fisica, userId }),   
                //body: JSON.stringify(fisica),                 
            });

            const data = await res.json();
            console.log(data);

        
            console.log('Formulario enviado con éxito');
            navigate('/diagnostico');

        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }

    }

  return (
    <>
    {regimenFiscal === 'moral' ?(
        <>
        <section className='registro-completo'>
            <div className='container'>
                <div className='registro-completo-container'>
                    <div className='registro-completo-box'>
                        <div className='registro-text'>
                            <h2>DATOS GENERALES</h2>                    
                        </div>
                        <div className='registro-inputs'>
                            <div className='box-input size-1'>
                                <label>Nombre Comercial de la Empresa <i>*</i></label>
                                <input type='text' name='nombre_comercial' value={moral.nombre_comercial} onChange={handleChange} />
                            </div>
                            <div className='registro-completo-content'>
                                <label>
                                    MiPyME 
                                    <input type="radio" value="fisica" checked={regimenFiscal === 'fisica'} onChange={handleOptionChange} />                           
                                </label>
                                <br />
                                <label>
                                    ONG 
                                    <input type="radio" value="moral" checked={regimenFiscal === 'moral'} onChange={handleOptionChange} />                            
                                </label>
                            </div>
                            {/* Regimen */}
                        
                            <div className='box-inputs size-2'>
                                <div>
                                    <label>Nombre Completo / Razón Social <i>*</i></label>
                                    <input type='text' name='razon_social' value={moral.razon_social} onChange={handleChange}/>
                                </div>
                                <div>
                                    <label>RFC <i>*</i></label>
                                    <input type='text' name='rfc' value={moral.rfc} onChange={handleChange}/>                                    
                                </div>
                            </div>
                            <div className='box-inputs size-3'>
                                <div>
                                    <label>Inicio de operaciones</label>
                                    <input type='date' name='inicio_operaciones'  value={moral.inicio_operaciones} onChange={handleChange}/>
                                </div>
                                <div>
                                    <label>Tipo de objeto <i>*</i></label>
                                    
                                    <select name='sector' value={moral.sector} onChange={handleChange}>
                                        <option value="">Selecciona una opción</option>
                                        <option value='Innovación_educación'>Innovación y educación</option>
                                        <option value='Sostenibilidad_medio_ambiente'>Sostenibilidad y medio ambiente</option>                                        
                                    </select>
                                   
                                </div>
                                <div>
                                    <label>Principal actividad <i>*</i></label>
                                    <input type='text' name='producto_servicio' value={moral.producto_servicio} onChange={handleChange}/>
                                </div>
                            </div>                           
                            <div className='box-inputs size-3'>
                                <div>
                                    <label>Sexo <i>*</i></label>
                                    <input type='text' name='sexo'  value={moral.sexo} onChange={handleChange}/>
                                </div>
                                <div>
                                    <label>Puesto <i>*</i></label>
                                    <input type='text' name='puesto' value={moral.puesto} onChange={handleChange} />
                                </div>
                                <div>
                                    <label>Teléfono</label>
                                    <input type='text' name='telefono' value={moral.telefono} onChange={handleChange} />
                                </div>
                            </div>
                            <div className='box-inputs size-2'>
                                <div>
                                    <label>Email <i>*</i></label>
                                    <input type='text' name='email' value={moral.email} onChange={handleChange}/>
                                </div>
                                <div>
                                    <label>Página web</label>
                                    <input type='text' name='pagina_web' value={moral.pagina_web} onChange={handleChange}/>
                                </div>
                            </div>                                                                                           
                        </div>

                    </div>
                
                        <div className='registro-completo-box'>
                            <div className='registro-text'>
                                <h2>DOMICILIO COMERCIAL <br /> DE LA EMPRESA</h2>                    
                            </div>
                            <div className='registro-inputs'>                                                   
                                                            
                                <div className='box-inputs size-3'>
                                    <div>
                                        <label>Código Postal <i>*</i></label>
                                        <input type='text' name='comercial_codigo_postal' value={moral.comercial_codigo_postal} onChange={handleChangeComercialMoral} />
                                    </div>
                                    <div>
                                        <label>Colonia <i>*</i></label>                                        
                                        <select name='comercial_colonia' value={moral.comercial_colonia} onChange={handleChange}>
                                            <option value="">Selecciona una opción</option>
                                            {colonias.map((colonia, index) => (
                                                <option key={index} value={colonia}>{colonia}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label>Ciudad <i>*</i></label>
                                        <input type='text' name='comercial_ciudad' value={moral.comercial_ciudad} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='box-inputs size-3'>
                                    <div>
                                        <label>Calle <i>*</i></label>
                                        <input type='text' name='comercial_calle' value={moral.comercial_calle} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>Número Ext. <i>*</i></label>
                                        <input type='text' name='comercial_numero_ext' value={moral.comercial_numero_ext} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>Número Int. </label>
                                        <input type='text' name='comercial_numero_int' value={moral.comercial_numero_int} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='box-inputs size-3'>
                                    <div>
                                        <label>Estado <i>*</i></label>
                                        <input type='text' name='comercial_estado' value={moral.comercial_estado} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>Delegación / Municipio <i>*</i></label>
                                        <input type='text' name='comercial_delegacion_municipio' value={moral.comercial_delegacion_municipio} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>País <i>*</i></label>
                                        <input type='text' name='comercial_pais' value={moral.comercial_pais} onChange={handleChange} />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='registro-completo-box'>
                            <div className='registro-text'>
                                <h2>DOMICILIO FISCAL</h2>                    
                            </div>
                            <div className='registro-inputs'>
                                <p>¿CORRESPONDE AL MISMO DOMICILIO COMERCIAL? </p>
                                <div className='registro-completo-content'>
                                    <label>
                                        Si
                                        <input type="radio" value="mismoDom" checked={domicilioFiscal === 'mismoDom'} onChange={handleDomicilioChange} />                           
                                    </label>
                                    <br />
                                    <label>
                                        No
                                        <input type="radio" value="otroDom" checked={domicilioFiscal === 'otroDom'} onChange={handleDomicilioChange} />                            
                                    </label>
                                </div>

                                {domicilioFiscal === 'otroDom' &&(
                                    <>   

                                    <div className='box-inputs size-3'>
                                        <div>
                                            <label>Código Postal <i>*</i></label>
                                            <input type='text' name='fiscal_codigo_postal' value={moral.fiscal_codigo_postal} onChange={handleChangeFiscalMoral} />
                                        </div>
                                        <div>
                                            <label>Colonia <i>*</i></label>                                        
                                            <select name='fiscal_colonia' value={moral.fiscal_colonia} onChange={handleChange}>
                                                <option value="">Selecciona una opción</option>
                                                {colonias1.map((colonia, index) => (
                                                    <option key={index} value={colonia}>{colonia}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label>Ciudad <i>*</i></label>
                                            <input type='text' name='fiscal_ciudad'  value={moral.fiscal_ciudad} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='box-inputs size-3'>
                                        <div>
                                            <label>Calle <i>*</i></label>
                                            <input type='text' name='fiscal_calle'  value={moral.fiscal_calle} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>Número Ext. <i>*</i></label>
                                            <input type='text' name='fiscal_numero_ext'  value={moral.fiscal_numero_ext}onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>Número Int.</label>
                                            <input type='text' name='fiscal_numero_int'  value={moral.fiscal_numero_int} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='box-inputs size-3'>
                                        <div>
                                            <label>Estado <i>*</i></label>
                                            <input type='text' name='fiscal_estado'  value={moral.fiscal_estado} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>Delegación / Municipio <i>*</i></label>
                                            <input type='text' name='fiscal_delegacion_municipio' value={moral.fiscal_delegacion_municipio} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>País <i>*</i></label>
                                            <input type='text' name='fiscal_pais'  value={moral.fiscal_pais} onChange={handleChange} />
                                        </div>
                                    </div>
                                      
                                    </>
                                )}
                                                                                
                            </div>
                                    
                            
                        </div>
                    
                        <div className='box-button'>                           
                            {/*<button onClick={handleSubmitMoral} disabled={!moral.nombre_comercial && !moral.razon_social}>Enviar</button>       */}  
                            
                            {alerta && (
                                <>
                                    <label className='alertaMensaje'>Por favor, completa todos los campos obligatorios </label> <br />
                                </>
                            )}
                            <button onClick={(e) => { if (isValidForm()) {  handleSubmitMoral(e);} else { setAlerta(true) }  }}>Enviar </button>                                     
                        </div>   
                </div>           
            </div>
        </section>
        </>
    ):(
        <>
        <section className='registro-completo'>
            <div className='container'>
                <div className='registro-completo-container'>
                    <div className='registro-completo-box'>
                        <div className='registro-text'>
                            <h2>DATOS GENERALES</h2>                    
                        </div>
                        <div className='registro-inputs'>
                            <div className='box-input size-1'>
                                <label>Nombre Comercial de la Empresa <i>*</i></label>
                                <input type='text' name='nombre_comercial' value={fisica.nombre_comercial} onChange={handleChange} />
                            </div>
                            <div className='registro-completo-content'>
                                <label>
                                MiPyME
                                    <input type="radio" value="fisica" checked={regimenFiscal === 'fisica'} onChange={handleOptionChange} />                           
                                </label>
                                <br />
                                <label>
                                    ONG
                                    <input type="radio" value="moral" checked={regimenFiscal === 'moral'} onChange={handleOptionChange} />                            
                                </label>*
                            </div>
                            {/* Regimen */}
                        
                                <div className='box-inputs size-3'>
                                    <div>
                                        <label>Nombre Completo / Razón Social</label>
                                        <input type='text' name='nombre_completo' value={fisica.nombre_completo} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>RFC<i>*</i></label>
                                        <input type='text' name='rfc' value={fisica.rfc} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>Inicio de operaciones <i>*</i></label>
                                        <input type='date' name='inicio_operaciones' value={fisica.inicio_operaciones} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='box-inputs size-2'>
                                    <div>
                                        <label>Fecha de Nacimiento</label>
                                        <input type='date' name='fecha_nacimiento' value={fisica.fecha_nacimiento} onChange={handleChange} />
                                    </div>                                                       
                                    <div>
                                        <label>Entidad de Nacimiento <i>*</i></label>
                                        <input type='text' name='entidad_nacimiento' value={fisica.entidad_nacimiento} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>CURP</label>
                                        <input type='text' name='curp' value={fisica.curp} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='box-inputs size-2'>
                                    <div>
                                        <label>Tipo de Sector <i>*</i></label>
                                        {/*<input type='text' name='sector' value={fisica.sector} onChange={handleChange} />*/}
                                        <select name='sector' value={fisica.sector} onChange={handleChange}>
                                            <option value="">Selecciona una opción</option>
                                            <option value='Primario'>Primario</option>
                                            <option value='Secundario'>Secundario</option>
                                            <option value='Terciario'>Terciario</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Producto o Servicios <i>*</i></label>
                                        <input type='text' name='producto_servicio'  value={fisica.producto_servicio} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='box-inputs size-3'>
                                    <div>
                                        <label>Sexo <i>*</i></label>
                                        <input type='text' name='sexo' value={fisica.sexo} onChange={handleChange}  />
                                    </div>
                                    <div>
                                        <label>Puesto <i>*</i></label>
                                        <input type='text' name='puesto' value={fisica.puesto} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>Teléfono</label>
                                        <input type='text' name='telefono' value={fisica.telefono} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='box-inputs size-2'>
                                    <div>
                                        <label>Email <i>*</i></label>
                                        <input type='text' name='email' value={fisica.email} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <label>Página web</label>
                                        <input type='text' name='pagina_web' value={fisica.pagina_web} onChange={handleChange} />
                                    </div>
                                </div>                                                                                                 
                        </div>

                    </div>
                
                        <div className='registro-completo-box'>
                            <div className='registro-text'>
                                <h2>DOMICILIO COMERCIAL <br /> DE LA EMPRESA</h2>                    
                            </div>
                            <div className='registro-inputs'>                                                   
                                                            
                                    <div className='box-inputs size-3'>
                                        <div>
                                            <label>Código Postal <i>*</i></label>
                                            <input type='text' name='comercial_codigo_postal' value={fisica.comercial_codigo_postal} onChange={handleChangeComercialFisica} />
                                        </div>
                                        <div>
                                            <label>Colonia <i>*</i></label>                                        
                                            <select name='comercial_colonia' value={fisica.comercial_colonia} onChange={handleChange}>
                                                <option value="">Selecciona una opción</option>
                                                {colonias2.map((colonia, index) => (
                                                    <option key={index} value={colonia}>{colonia}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label>Ciudad <i>*</i></label>
                                            <input type='text' name='comercial_ciudad' value={fisica.comercial_ciudad} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='box-inputs size-3'>
                                        <div>
                                            <label>Calle <i>*</i></label>
                                            <input type='text' name='comercial_calle' value={fisica.comercial_calle} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>Número Ext. <i>*</i></label>
                                            <input type='text' name='comercial_numero_ext' value={fisica.comercial_numero_ext} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>Número Int.</label>
                                            <input type='text' name='comercial_numero_int' value={fisica.comercial_numero_int} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className='box-inputs size-3'>
                                        <div>
                                            <label>Estado <i>*</i></label>
                                            <input type='text' name='comercial_estado' value={fisica.comercial_estado} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>Delegación / Municipio <i>*</i></label>
                                            <input type='text' name='comercial_delegacion_municipio' value={fisica.comercial_delegacion_municipio} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>País <i>*</i></label>
                                            <input type='text' name='comercial_pais' value={fisica.comercial_pais} onChange={handleChange} />
                                        </div>
                                    </div>

                            </div>

                        </div>
                        <div className='registro-completo-box'>
                            <div className='registro-text'>
                                <h2>DOMICILIO FISCAL</h2>                    
                            </div>
                            <div className='registro-inputs'>
                                <p>¿CORRESPONDE AL MISMO DOMICILIO COMERCIAL? </p>
                                <div className='registro-completo-content'>
                                    <label>
                                        Si
                                        <input type="radio" value="mismoDomFiscal" checked={domicilioFiscalFisica === 'mismoDomFiscal'} onChange={handleDomicilioChange} />                           
                                    </label>
                                    <br />
                                    <label>
                                        No
                                        <input type="radio" value="otroDomFiscal" checked={domicilioFiscalFisica === 'otroDomFiscal'} onChange={handleDomicilioChange} />                            
                                    </label>
                                </div>

                                {domicilioFiscalFisica === 'otroDomFiscal' &&(
                                    <>                        
                                        <div className='box-inputs size-3'>
                                            <div>
                                                <label>Código Postal <i>*</i></label>
                                                <input type='text' name='fiscal_codigo_postal' value={fisica.fiscal_codigo_postal} onChange={handleChangeFiscalFisica} />
                                            </div>
                                            <div>
                                                <label>Colonia <i>*</i></label>       
                                                <select name='fiscal_colonia' value={fisica.fiscal_colonia} onChange={handleChange}>
                                                    <option value="">Selecciona una opción</option>
                                                    {colonias3.map((colonia, index) => (
                                                        <option key={index} value={colonia}>{colonia}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <label>Ciudad <i>*</i></label>
                                                <input type='text'  name='fiscal_ciudad'  value={fisica.fiscal_ciudad} onChange={handleChange} />
                                            </div>
                                        </div>

                                        <div className='box-inputs size-3'>
                                            <div>
                                                <label>Calle <i>*</i></label>
                                                <input type='text' name='fiscal_calle'  value={fisica.fiscal_calle} onChange={handleChange} />
                                            </div>
                                            <div>
                                                <label>Número Ext. <i>*</i></label>
                                                <input type='text' name='fiscal_numero_ext'  value={fisica.fiscal_numero_ext} onChange={handleChange} />
                                            </div>
                                            <div>
                                                <label>Número Int. </label>
                                                <input type='text' name='fiscal_numero_int'  value={fisica.fiscal_numero_int} onChange={handleChange} />
                                            </div>
                                        </div>                           
                                       
                                        <div className='box-inputs size-3'>
                                            <div>
                                                <label>Estado <i>*</i></label>
                                                <input type='text'  name='fiscal_estado'  value={fisica.fiscal_estado} onChange={handleChange} />
                                            </div>
                                            <div>
                                                <label>Delegación / Municipio <i>*</i></label>
                                                <input type='text'  name='fiscal_delegacion_municipio' value={fisica.fiscal_delegacion_municipio} onChange={handleChange}  />
                                            </div>
                                            <div>
                                                <label>País <i>*</i></label>
                                                <input type='text'  name='fiscal_pais'  value={fisica.fiscal_pais} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </>
                                )}
                                                                                
                            </div>
                                    
                            
                        </div>
                    
                        <div className='box-button'>                           
                            
                            {alerta && (
                                <>
                                    <label className='alertaMensaje'>Por favor, completa todos los campos obligatorios </label> <br />
                                </>
                            )}
                            <button onClick={(e) => { if (isValidFormFisica()) {  handleSubmitFisica(e);} else { setAlerta(true) }  }}>Enviar </button> 

                                                                        
                        </div>   
                </div>           
            </div>
        </section>
        </>
    )

    }
   
    
    </>
  )
}
