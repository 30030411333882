import React, {useState, useEffect} from 'react'
import { Outlet, Link  } from "react-router-dom";
import '../css/footer.css'
import facebook from '../assets/images/redes_sociales/facebook.svg'
import twitter from '../assets/images/redes_sociales/twitter.svg'
import instagram from '../assets/images/redes_sociales/instagram.svg'
import youtube from '../assets/images/redes_sociales/youtube.svg'
import logo from '../assets/images/logos/apex_APEX5.png'
import ods from '../assets/images/ods/ods.png'

export default function Footer() {
    const [task, setTask] = useState({
        email:'',
        fecha_alta: '',
        fecha_baja: null,
    })
    const [alert, setAlert] = useState(false);

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
        setTask((prevTask) => ({ ...prevTask, fecha_alta: currentDate }));
    }, []);


    const handleChange = (e) => {
        setTask({...task, [e.target.name]: e.target.value});
        console.log("task", task);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("task", task);

        try {        
            await fetch('https://apexlpz.bajaferries.com.mx/newsletter',{            
                method:'POST',
                body: JSON.stringify(task),
                headers: {'Content-Type': 'application/json'},
            });
    
            // Limpiar campos después de enviar el correo
            setTask({
                email: '',
                fecha_alta: new Date().toISOString().split('T')[0],
                fecha_baja: 'null',
            });
            setAlert(true);
            console.log('Formulario enviado con éxito');
            
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    }

  return (
    <>
    <section className='footer'>
        <div className='container'>
            <div className='footer_container'>
                <div className='footer_content'>
                    <div className='footer_logo_box'>
                        <img src={logo} alt='logo apex blanco' />
                    </div>
                    <div className='footer_ods'>
                        <img src={ods} alt='logo apex blanco' />                        
                    </div>
                    {alert &&(                
                        <div className='alertMessage_footer'>
                            <label>¡Te has suscrito correctamente!</label>                
                        </div>
                    )}
                    <div className='footer_newsletter'>                        
                        <form onSubmit={handleSubmit} className='footer_newsletter'>                           
                            <input type='email' name='email' value={task.email} onChange={handleChange} placeholder='NEWSLETTER'/>
                            <button type='submit'> <i class="fi fi-ss-angle-right"></i> </button>
                        </form>
                    </div>
                </div>
                <div className='footer_content'>
                    <div className='list_opc'>
                        <ul>
                            <li>inicio</li>
                            <li>premios apex</li>
                            <li>comité evaluador</li>
                            <li>aliados</li>
                            <li>registro de usuario</li>
                        </ul>
                    </div>
                    <div className='list_opc'>
                        <ul>                     
                            <li><a href={`${process.env.PUBLIC_URL}/descargas/convocatoria-web.pdf`} rel='noopener noreferrer' target="_blank">Convocatoria</a>, <a href={`${process.env.PUBLIC_URL}/descargas/bases-de-participacion.pdf`} rel='noopener noreferrer' target="_blank">Bases</a> y <a href={`${process.env.PUBLIC_URL}/descargas/guia-apex.pdf`} rel='noopener noreferrer' target="_blank">GUÍA</a> </li>                            
                            <li><Link to="/terminos-y-condiciones-participacion">Términos y condiciones de participación</Link></li>                      
                            <li><Link to="/terminos-y-condiciones">Términos y condiciones</Link></li>
                            <li><a href="mailto:apexlpz@bajaferries.com.mx"> Contacto</a></li>
                            <li><Link to="/aviso-de-privacidad">Aviso de privacidad</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='hr-line'></div>
            <div className='copyright'>
                <label>Copyright © APEX. Todos los derechos reservados</label>
                <div className='footer_redes_sociales'>               
                    <div className='box_link_social'>
                        <a href="https://www.facebook.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={facebook} alt='facebook oficial bajaferries' className='bg_spotify' /> </a>                                                                            
                    </div>
                    <div className='box_link_social'>                                        
                        <a href="https://x.com/bajaferries" rel='noopener noreferrer' target="_blank"><img src={twitter} alt='twitter oficial bajaferries' className='bg_spotify' /> </a>                                      
                    </div>
                    <div className='box_link_social'>
                        <a href="https://www.instagram.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={instagram} alt='instagram oficial bajaferries' className='bg_spotify' /> </a>                                                                                
                    </div>
                    <div className='box_link_social'>
                        <a href="https://www.youtube.com/@bajaferries2914/" rel='noopener noreferrer' target="_blank"><img src={youtube} alt='instagram oficial bajaferries' className='bg_spotify' /> </a>                                                                                
                    </div>
                             
                                             
                </div>
                                    
            </div>
        </div>
    </section>

    <Outlet />
    
    </>
  )
}
