import React, {useEffect, useState} from 'react'
import '../css/user_list.css'
import Navbar from '../components/NavbarAdmin'

export default function ListONG() {
    const [tasks, setTasks] = useState([]);

    const loadTasks = async () => {
        //const response = await fetch('https://apexlpz.bajaferries.com.mx/usuario_evidencia_moral');
        //const response = await fetch('https://apexlpz.bajaferries.com.mx/ong');
        const response = await fetch('http://localhost:3091/ong');
        

        const data = await response.json();
        console.log(data);
        setTasks(data);
      }

      useEffect(() =>{
        loadTasks();
      },[])

    const handleDownload = async (userId) => {
        const link = document.createElement('a');
        link.href = `http://localhost:3091/download/${userId}`;
        link.setAttribute('download', `${userId}.zip`); 
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const handleDownloadExcel = async (userId) => {
        const link = document.createElement('a');
        link.href = `http://localhost:3091/download_excel_ong/${userId}`;
        link.setAttribute('download', 'ONG.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

  return (
    <>
       <Navbar />

       <section className='lista-usuarios'>
            <div className='container'>
                <div className='lista-usuario-persona-fisica'>
                    <h2>ONG´s</h2>
                    
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>#</th>                                                        
                                <th>ID</th>     
                                <th>Nombre comercial</th>
                                <th>Des. Organizacional</th>
                                <th>Sostenible</th>
                                <th>Habilidades</th>
                                <th>Total %</th>
                                <th>Evidencia</th> 
                                <th>Diagnostico</th>                                 
                            </tr>
                        </thead>
                        <tbody>
                        {tasks.map((task, index) => {
                        const desarrollo = parseFloat(task.desarrollo_porcent_evidencia) || 0;
                        const sostenible = parseFloat(task.sostenible_porcent_evidencia) || 0;
                        const habilidades = parseFloat(task.habilidades_porcent_evidencia) || 0;

                        const totalPorcentaje = ((desarrollo + sostenible + habilidades) / 3).toFixed(2);

                            return (
                                <tr key={task.id}>
                                <td>{index + 1}</td>       
                                <td>{task.usuario_id}</td>                                                                                                    
                                <td>{task.nombre_comercial}</td>    
                                <td>{desarrollo.toFixed(2)}</td>                           
                                <td>{sostenible.toFixed(2)}</td>                           
                                <td>{habilidades.toFixed(2)}</td>  
                                <td>{totalPorcentaje}</td>                                                                                  
                                <td>
                                    <button onClick={() => handleDownload(task.usuario_id)}>Descargar</button>
                                </td>   
                                <td>
                                    <button onClick={() => handleDownloadExcel(task.usuario_id)}>Descargar</button>
                                </td>                  
                            </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
       </section>
    
    </>
  )
}
