import React from 'react'
import Footer from '../components/Footer'

export default function TerminosCondicionesParticipacion() {
  return (
    <>
    <section className='TerminosCondicionesParticipacion'>
        <div className='container'>
            <h2>Términos y condiciones del Programa Distintivo Apex La Paz.</h2>
            <div>
                <p>En este texto se encuentran los términos y condiciones de participación en el Programa Distintivo Apex La Paz. Usted acepta estar obligado por estos términos y condiciones desde el momento en el que acepte participar en el proceso de registro, y una vez concluido este.</p>
                <p><strong>Organización</strong></p>
                <p>El programa está organizado por Baja Ferries en coordinación con el Honorable Ayuntamiento de La Paz, y en colaboración con el Consejo Coordinador Empresarial de La Paz (CCE), Cámara de Comercio, Servicios y Turismo de La Paz (CANACO LA PAZ), y el Centro de Investigaciones Biológicas del Noroeste S.C. (CIBNOR).</p>
                <p><strong>Objetivo</strong></p>
                <p>Reconocer el talento y compromiso de los líderes y colaboradores de las Micro, Pequeñas y Medianas Empresas (MipYMes) y Organizaciones de la Sociedad Civil (ONG´s) que radiquen en La Paz, Baja California Sur. Así como, establecer las condiciones para el proceso de registro a la convocatoria a la participación en el distintivo APEX LA PAZ (Acreditación para Pequeñas Empresas con Excelencia en Aprendizaje) de las Micro, Pequeñas y Medianas Empresas (MiPyMES) y Organizaciones de la Sociedad Civil (ONG´s).</p>
                <p>El programa cuenta con diferentes categorías de participación, la cual, dependerá del tamaño de la organización para las MIPYMES del sector al que estas pertenezcan y para las ONG´s, según su objeto social.</p>
                <p>Tamaño de la organización:</p>
                <ul>
                    <li>-	Micro (1 a 10 empleados)</li>
                    <li>-	Pequeña (11 a 50 empleados)</li>
                    <li>-	Mediana (51 a 250 empleados)</li>
                </ul>
                <p><strong>1.	Para MIPYMES: </strong></p>
                <p>Reconocimiento diferenciado por sector: </p>
                <ul>
                    <li>-	Primario: Agricultura, ganadería, pesca, acuacultura, minería.</li>
                    <li>-	Secundario: Industrial (Empresas dedicadas a la manufactura o construcción, transformación de bienes).</li>
                    <li>-	Terciario: Sector Servicios (Comercio, transporte, servicios financieros, servicios sociales).</li>
                </ul>
                <p><strong>2.	Para organizaciones de la sociedad civil:</strong></p>
                <p>Reconocimiento diferenciado por su objeto social:</p>
                <ul>
                    <li>-	Innovación y educación. </li>
                    <li>-	Sostenibilidad y medio ambiente. </li>
                </ul>
                <p><strong>Procedimientos y fases del programa.</strong></p>
                <p><strong>1.	Proceso de inscripción.</strong></p>
                <p>El plazo de inscripción a la participación en el distintivo Apex La Paz, se realizará de manera digital ingresando a la página  <a href="https://apexlpz.bajaferries.com.mx" rel='noopener noreferrer' target="_blank">https://apexlpz.bajaferries.com.mx</a>, a partir del 20 de septiembre y hasta el 21 de octubre de 2024.</p>
                <p>El proceso de inscripción se realizará de acuerdo a la guía de participación. </p>
                <p><strong>2.	Procedimiento y fases del programa.</strong></p>
                <p><strong>2.1	Admisión y selección. </strong></p>
                <p>Cada una de las categorías se clasificarán de acuerdo al tamaño de la organización, reconocimiento diferenciado por sector y organizaciones de la sociedad civil de distintos objetos sociales. </p>
                <p><strong><i>Consideraciones de las categorías </i></strong></p>
                <p><i>A sugerencia del Consejo Evaluador, se podrá reclasificar a las organizaciones en otra categoría de participación cuando su actividad o estructura así lo amerite. En tal caso, la organización participante será notificada sobre esta modificación y su fundamento.</i></p>
                <p>Las empresas u ONG´s participantes que cumplan con al menos el 70% del cumplimiento pasaran a una segunda etapa de evaluación y revisión de la evidencia documental por parte del comité evaluador. </p>
                <p><strong>2.3	Mediación y cumplimiento del cuestionario</strong></p>
                <p>El cuestionario de evaluación se divide en 3 ámbitos <strong>1) Capacitación sostenible, 2) capacitación en desarrollo organizacional y 3) capacitación en nuevas habilidades.</strong>  Cada uno de estos ámbitos tiene un valor del 100%, y el cumplimiento se mediera en función de las evidencias presentadas. Para medir este cumplimiento, cada pregunta del cuestionario tiene un valor asignado previamente por el comité organizador, por lo que las evidencias presentadas deberán de responder a los siguientes criterios: </p>
                <ul>
                    <li>a.	Precisión </li>
                    <li>b.	Autenticidad</li>
                    <li>c.	Relevancia</li>
                </ul>
                <p><strong>2.4	Proceso de evaluación.</strong></p>
                <p>Se llevará a cabo del <strong>22 de octubre al 01 de noviembre de 2024,</strong> por el consejo evaluador.</p>
                <ul>
                    <li>•	Etapa 1. Análisis de evidencias.</li>
                    <li>•	Etapa 2. Selección.</li>
                    <li>•	Etapa 3. Deliberación. </li>
                </ul>
                <p>Los resultados finales se determinarán según el cumplimiento de las evidencias revisadas por el comité evaluador. En caso de empate, se llevarán a cabo entrevistas individuales con las organizaciones participantes para identificar acciones de mejora relacionadas con el desarrollo de la fuerza laboral que las diferencian una de la otra y ayuden a la madurez empresarial. </p>
                <p><strong>2.5 Deliberación </strong></p>
                <p>Para la selección de los ganadores, se elegirán dos empresas por tipo de sector (primario, secundario, terciario) y un ganador por cada objeto social para las organizaciones de la sociedad civil. </p>
                <p>El 06 de noviembre de 2024, se llevará a cabo la notificación de los resultados a través de correo electrónico y en el sitio web   <a href="https://apexlpz.bajaferries.com.mx" rel='noopener noreferrer' target="_blank">https://apexlpz.bajaferries.com.mx</a> así como en las redes sociales de los aliados involucrados en el programa. </p>
                <p><strong>3.	Clausura y Premiación </strong></p>
                <p>La clausura y entrega de reconocimientos se llevará a cabo el 14 de noviembre de 2024. Cada ganador recibirá una estatuilla del Distintivo como reconocimiento, además de un incentivo en especie que consiste en la entrega de equipo tecnológico para apoyar el desarrollo de la capacitación en su organización. </p>
                <p><strong>Derecho de información y seguimiento.</strong></p>
                <p>A efectos de seguimiento y del máximo aprovechamiento del equipo tecnológico entregado para su desarrollo, Baja Ferries, deberá ser informado de forma suficiente sobre la marcha de la capacitación en su organización. </p>
                <p><strong>Responsabilidades</strong></p>
                <p>Las siguientes responsabilidades serán asumidas por los participantes o candidatos desde el momento en que se inscriban:</p>
                <ul>
                    <li>•	Se debe de registrar en la página y registrar la información acorde a lo solicitado, de buna fe y de la manera más profesional y leal posible.</li>
                    <li>•	No se podrá presentar información que pueda ser fraudulenta, falsa, ilegal o que vulnere los derechos de terceros.</li>
                    <li>•	Se descalificará a cualquiera que incurra en el incumplimiento de estas Condiciones de Participación, en cualquiera de las etapas del proceso de evaluación. </li>                    
                </ul>
                <p><strong>Difusión del programa.</strong></p>
                <p>Los participantes aceptan aparecer en la prensa para la entrega de los reconocimientos del distintivo y el premio en especie.</p>
                <p><strong>Propiedad intelectual e industrial. </strong></p>
                <p>Los presentes términos y condiciones de uso no pretenden transferir derechos de propiedad intelectual e industrial entre las partes. </p>
                <p>Ninguno de los participantes, pueden utilizar los derechos de propiedad intelectual o industrial, incluidos nombres, marcas, logotipos, o materiales del Organizador y las entidades, en ningún momento ni, de cualquier forma. </p>
                <p><strong>Responsabilidad</strong></p>
                <p>El Organizador no tendrá ninguna responsabilidad por:</p>
                <ul>
                    <li>1.	Cualquier información incorrecta o inexacta proporcionada por error sobre el proceso de inscripción o sobre el Programa en general.</li>
                    <li>2.	Falta de recepción de información.</li>
                    <li>3.	Fallos técnicos y/o humanos.</li>
                </ul>
                <p>Los participantes deben de conservar una copia de toda información que ingrese a la página, conservando la original. El Organizador no será responsable de ninguna perdida de datos o documentación.  </p>
                <p><strong>Contacto con el Organizador. </strong></p>
                <p>En caso de duda sobre el programa puede ponerse en contacto mediante la dirección de correo electrónico <a href="mailto:apexlpz@bajaferries.com.mx">apexlpz@bajaferries.com.mx.</a></p>
                <p>Si sus datos de contacto cambian en cualquier momento durante el transcurso del Programa, comuníquelo al Organizador de forma inmediata.</p>
                <p>Las notificaciones que el Organizador le envíe a los participantes se considerarán efectivas. </p>
                <p><strong>Otros aspectos.</strong></p>
                <p>El Organizador se reserva el derecho de modificar, cancelar o suspender el Programa o cualquier parte del mismo, en cualquier momento y por cualquier motivo, principalmente, aunque no limitado a, motivos organizativos, de fuerza mayor, circunstancias que caigan fuera de nuestro control o por la no presentación de proyectos adecuados o de suficiente entidad.</p>
                <p>Se podrá suspender o descalificar a cualquier Participante, en cualquier momento, cuando el Organizador lo considere necesario para proteger la integridad o el funcionamiento del Programa y/o intereses legítimos del Organizador, o ante la sospecha de que un participante ha violado estos términos o ha actuado de forma inadecuada, poco ética o inapropiada.</p>
                <p>No se considerará una renuncia a los derechos del Organizador, la no aplicación de cualquier disposición de forma inmediata.</p>
                <p>La invalidez o inaplicabilidad de cualquier disposición contenida en estos términos no afectará en modo alguno la existencia o validez de los términos restantes que permanecerán en pleno vigor y efecto. Si una disposición resulta inaplicable, el Organizador podrá sustituirla por una disposición aplicable que refleje de la forma más fiel posible la intención valida de la disposición sustituida.</p>
                <p>Estas Condiciones de Participación, junto con el Aviso de Privacidad, conforman todos los términos aplicables a este proceso de solicitud y al Programa y operan con independencia y exclusión de cualquier material publicitario u otra documentación.</p>
                <p><strong>Jurisdicción aplicable. </strong></p>
                <p>En caso de surgir alguna controversia derivada de las presentes condiciones de servicio, así como para todo aquello que no esté expresamente estipulado en las mismas, las partes se someten a la jurisdicción de los tribunales competentes de la ciudad de La Paz, Baja California Sur, México; por lo tanto, el pasajero renuncia al fuero que pudiere corresponderle por razón de su domicilio presente o futuro o cualquier otra causa.</p>
            </div>

        </div>
    </section>
    <Footer />
    </>
  )
}
