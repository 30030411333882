import React from 'react'
import '../css/terminos.css'
import Footer from '../components/Footer'
export default function TerminosCondiciones() {
  return (
    <>
    <section className='terminos_condiciones'>
        <div className='container'>
            <h2>Términos y condiciones del Programa Distintivo Apex La Paz</h2>
            <div>
                <p>La utilización de este sitio constituye el pleno y expreso consentimiento por parte del usuario para observar y sujetarse a los términos y condiciones del programa <strong>Distintivo Apex La Paz</strong>, organizado por <strong>Baja Ferries, S.A.P.I de C.V.</strong>, y otras instituciones, que aquí se contienen, así como respecto de las políticas de privacidad y, en su caso, cualesquiera otros documentos que conformen parte o regulen la participación del usuario en este sitio. En el supuesto que cualquiera de los términos a los que habrá de sujetarse el usuario cuando acceda a este sitio sean contrarios a sus intereses, deberá abstenerse de hacer uso de este.</p>
                <p><strong>Finalidad</strong></p>
                <p>El presente sitio web tiene como objetivo la participación del usuario en el programa denominado <strong>Distintivo Apex La Paz</strong>, a través de la presente plataforma, el usuario podrá realizar el registro y diagnostico empresarial con el fin de que “<strong>Baja Ferries</strong>” pueda revisar la información proporcionada y el perfil de la empresa. </p>
                <p>Por favor lea nuestras bases de términos y condiciones del programa <strong>Distintivo Apex La Paz</strong>  <a href="https://apexlpz.bajaferries.com.mx" rel='noopener noreferrer' target="_blank">https://apexlpz.bajaferries.com.mx</a> , que se incorpora aquí como referencia. Las cuales el usuario deberá observar de manera obligatoria durante la participación del programa.</p>
                <p><strong>Obligaciones del usuario</strong></p>
                <p>La persona que haga uso del presente sitio web deberá aceptar los presentes Términos y Condiciones, por lo que está obligado a analizarlos detenidamente antes de continuar, ya que por el simple uso o acceso a la página que integra el programa <strong>Distintivo Apex La Paz</strong>, se entenderá que acepta obligarse en cumplirlos. En caso de no estar de acuerdo en sujetarse a los mismos, debe suspender inmediatamente su uso y abstenerse de acceder al sitio, así como a la información, contenidos o programas que formen parte del mismo.</p>
                <p>El consentimiento o aceptación expresa a que refiere el párrafo anterior tiene fundamento en el artículo 1,708 del Código Civil para el Estado de Baja California Sur, el cual refiere que el consentimiento expreso es “cuando se manifiesta verbalmente, por escrito o por signos inequí¬vocos”.</p>
                <p>Estos Términos y Condiciones podrán ser modificados libremente por <strong>Baja Ferries, S.A.P.I de C.V.</strong> (en adelante “<strong>Baja Ferries</strong>”), por lo que se recomienda realizar una consulta periódica de los mismos para conocer las disposiciones vigentes al momento de acceder al sitio web.</p>
                <p>La persona usuaria tendrá acceso al portal a través de su dominio de internet  <a href="https://impulso.bajaferries.com.mx/login_user" rel='noopener noreferrer' target="_blank">https://impulso.bajaferries.com.mx/login_user</a>  y por medio de sus funcionalidades y contenidos podrán realizar diversas operaciones. La persona usuaria acepta ser el único responsable de la información que utiliza, opera y publica en el portal, así como la existencia y veracidad de la información y documentación referente a las operaciones que se realicen.</p>
                <p><strong>El uso del sitio es bajo la exclusiva responsabilidad del usuario.</strong></p>
                <p>Se autoriza al Usuario a visualizar y descargar los materiales contenidos en el sitio solamente para su uso personal y no para un uso comercial.</p>
                <ul>
                    <li>1.	El Usuario deberá conservar en todas las copias de los materiales descargados, todos los avisos sobre derechos de autor y propiedad intelectual de los materiales, contenidos en los mismos;</li>
                    <li>2.	El Usuario no deberá modificar, reproducir o mostrar pública o comercialmente los materiales, ni podrá distribuir o utilizarlos con algún propósito público o comercial;</li>
                    <li>3.	El Usuario no deberá transferir los materiales a ningún tercero.</li>
                </ul>
                <p>Los contenidos del sitio web tales como textos, gráficos, imágenes, logos, íconos de botón, programas de computación (software) y cualquier otro contenido, obra o elemento que sean objeto de protección del derecho de la propiedad intelectual deberán utilizarse por la persona usuaria únicamente para los fines para los cuales han sido creados. El uso no autorizado del contenido del sitio web será sancionado en términos de la legislación aplicable.</p>
                <p>Nos reservamos el derecho de suspender, interrumpir o dejar de operar el sitio y los servicios, en cualquier momento.</p>
                <p><strong>Contraseña y Seguridad</strong></p>
                <p>Como usuario del Sitio Web, usted es el único responsable de mantener la confidencialidad y seguridad de su contraseña y cuenta. Usted comprende y acepta que es totalmente responsable de todas las acciones y publicaciones realizadas desde su cuenta. Las cuentas no son transferibles. Usted accede a notificar al Sitio Web de inmediato a <a href="mailto:heriberto.torres@bajaferries.com.mx"> heriberto.torres@bajaferries.com.mx</a> si tiene conocimiento de algún uso no autorizado de su cuenta.</p>
                <p><strong>Terminación</strong></p>
                <p>El “<strong>Baja Ferries</strong>” se reserva el derecho de cancelar su cuenta o restringir el acceso a su cuenta, o eliminar cualquier contenido publicado a través de su cuenta, con o sin aviso, al criterio exclusivo del <strong>Distintivo Apex La Paz</strong> , y sin ninguna responsabilidad hacia usted.</p>
                <p><strong>Uso de cookies.</strong></p>
                <p>El sitio web utiliza cookies para obtener información estadística sobre el uso del mismo. Una "<strong>Cookie</strong>" es un componente que almacena datos para asegurar la confidencialidad de los datos que pasan por la red de internet, con el fin de garantizar que la información no sea observada por otros usuarios. En cualquier momento se puede eliminar la cookie que utiliza el sitio web accediendo al menú de preferencia. Igualmente puede impedir que vuelva a instalarse dicho archivo, realizando los ajustes correspondientes en dichas preferencias de configuración.</p>
                <p><strong>Términos de uso adicionales.</strong></p>
                <p>La información, datos, manifestaciones, actos y hechos jurídicos que se deriven por el uso del sitio web, estarán sujetos a la legislación que resulte aplicable, en relación a cada operación que se realice.</p>
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}
