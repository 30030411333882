import React from 'react'
import Footer from '../components/Footer'
export default function ComiteEvaluador() {
  return (
    <>
    <section className='comite'>
    <div className='container'>
        
        <div className='comite_container'>
            <h2>comité evaluador</h2>
            <div className='comite_content'>
                <div className='comite_box'>
                    <div className='comite_box_img'>

                    </div>
                    <div className='comite_box_text'>
                        <h3>Nombre</h3>
                        <label>representante bajaferries</label>
                        <p>Lorem Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet</p>
                    </div>
                    
                </div>
                <div className='comite_box'>
                    <div className='comite_box_img'>

                    </div>
                    <div className='comite_box_text'>
                        <h3>Nombre</h3>
                        <label>representante bajaferries</label>
                        <p>Lorem Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet</p>
                    </div>
                    
                </div>
                <div className='comite_box'>
                    <div className='comite_box_img'>

                    </div>
                    <div className='comite_box_text'>
                        <h3>Nombre</h3>
                        <label>representante bajaferries</label>
                        <p>Lorem Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet</p>
                    </div>
                    
                </div>
                <div className='comite_box'>
                    <div className='comite_box_img'>

                    </div>
                    <div className='comite_box_text'>
                        <h3>Nombre</h3>
                        <label>representante bajaferries</label>
                        <p>Lorem Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet</p>
                    </div>
                    
                </div>

            </div>

        </div>
    </div>

</section>
<Footer />
</>
  )
}
