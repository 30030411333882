import React from 'react'
import '../css/aliados_section.css'
import bf from '../assets/images/aliados/BajaFerries.png'
import canaco from '../assets/images/aliados/canaco.png'
import CIBNOR from '../assets/images/aliados/CIB nor.png'
import cce from '../assets/images/aliados/CCE.png'
import red from '../assets/images/aliados/Red de Ciudades.png'
import unesco from '../assets/images/aliados/unesco.png'


import Footer from '../components/Footer'
export default function Aliados() {
  return (
    <>
        <section className='aliados-section'>
            <div className='container'>
                <div className='aliados-section-container'>
                    <div className='aliados-section-content'>
                        <div className='aliados-section-box1 img'>
                            <img src={bf} alt='logo baja ferries'/>
                        </div>
                        <div className='aliados-section-box1 text'>
                            <h2>Baja Ferries</h2>
                            <p>Baja Ferries es el grupo logístico-naviero líder en el sector de carga y pasaje en México. Conecta pasajeros desde y hacia Baja California Sur y ofrece soluciones logísticas integrales en todo el país para el traslado de mercancías, impulsando el desarrollo del cabotaje en México. Empresa socialmente responsable por más de 9 años consecutivos. </p>
                            <a href="https://www.bajaferries.com.mx/" rel='noopener noreferrer' target="_blank"> Ir al sitio web </a>                                                                                
                        </div>
                    </div>
                    <div className='aliados-section-content'>                        
                        <div className='aliados-section-box1 text'>                            
                            <h2>CANACO La Paz</h2>
                            <p>Cámara empresarial firme, consolidada e innovadora; con más de 85 años representando, defendiendo y promoviendo al comercio formal y legalmente establecido, brindando servicios y beneficios a las empresas, favoreciendo el desarrollo de la comunidad, a través de representación institucional y capacitación constante.</p>
                            <a href="https://canacolapaz.com/" rel='noopener noreferrer' target="_blank"> Ir al sitio web </a>           
                        </div>
                        <div className='aliados-section-box1 img'>
                            <img src={canaco} alt='logo baja ferries'/>
                        </div>
                    </div>
                    <div className='aliados-section-content'>      
                        <div className='aliados-section-box1 img'>
                            <img src={CIBNOR} alt='logo baja ferries'/>
                        </div>                  
                        <div className='aliados-section-box1 text'>                            
                            <h2>CIBNOR </h2>
                            <p>Contribuir con responsabilidad social para llevar a México a su máximo potencial mediante la generación y divulgación de conocimiento de frontera, la innovación y la formación de científicos y expertos en el campo de las ciencias biológicas y en el uso, manejo y preservación de los recursos naturales. Asimismo, la Coordinación de Vinculación, Innovación y Transferencia de Conocimiento a la Sociedad (COVITECS), contribuye a la detección de necesidades de innovación sustentable e inclusiva de la sociedad, de la industria y del gobierno orientados a la resolución de problemas y retos sociales incluyendo las del sector productivo; en ese sentido, la contribución en formación y capacitación desde el CIBNOR a la sociedad se vuelve un eje fundamental en nuestro quehacer.</p>
                            <a href="https://www.cibnor.gob.mx/" rel='noopener noreferrer' target="_blank"> Ir al sitio web </a>           
                        </div>
                        
                    </div>
                    <div className='aliados-section-content'>                                              
                        <div className='aliados-section-box1 text'>                            
                            <h2>CCE La Paz </h2>
                            <p>El Consejo Coordinador Empresarial de La Paz es un organismo de adhesión voluntaria e independiente de cada una de las organizaciones que le constituyen. Se encuentra integrado por organismos empresariales que agrupan a los diversos sectores productivos de nuestro Estado (Baja California Sur) con el objetivo de unir, integrar y promover la participación de estos organismos a través de su coordinación, influyendo en el entorno político, económico y social, dentro de un ambiente de responsabilidad solidaria, para el desarrollo integral de Baja California Sur.</p>
                            <a href="https://www.ccelapaz.com/" rel='noopener noreferrer' target="_blank"> Ir al sitio web </a>   
                        </div>
                        <div className='aliados-section-box1 img'>
                            <img src={cce} alt='logo baja ferries'/>
                        </div>                          
                    </div>
        
                    <div className='aliados-section-content'>  
                        <div className='aliados-section-box1 img'>
                            <img src={red} alt='logo baja ferries'/>
                        </div>                                            
                        <div className='aliados-section-box1 text'>                            
                            <h2>Red de ciudades del aprendizaje de México  </h2>
                            <p>La Red de Ciudades del Aprendizaje de México A.C. es un organismo multisectorial que tiene por objetivo, posicionar al aprendizaje a lo largo de la vida como el principal factor de transformación y desarrollo sostenible de México. La Red se fundó en el año 2019, en la Biblioteca Palafoxiana, por los Presidentes Municipales y organizaciones de la sociedad civil de las sietes ciudades con la Designación de UNESCO (Puebla, Santiago, Ciudad de México, Tecamachalco, Mineral de la Reforma, Atlixco, Izucar de Matamoros). Actualmente contamos con 13 ciudades que se han adherido a la Red, entre ellas Querétaro, San Luis Potosí, Mérida, Pachuca y La Paz.</p>
                            <a href="https://www.uil.unesco.org/es/learning-cities" rel='noopener noreferrer' target="_blank"> Ir al sitio web </a>  
                        </div>
                                                 
                    </div>
                    
                    <div className='aliados-section-content'>                                              
                        <div className='aliados-section-box1 text'>                            
                        <h2>UNESCO Red mundial de ciudades del aprendizaje </h2>
                        <p>La Red Mundial de Ciudades del Aprendizaje de la UNESCO se integra por 356 municipios y apoya y mejora la práctica del aprendizaje a lo largo de toda la vida en las ciudades del mundo por promover el diálogo sobre políticas y el aprendizaje mutuo entre ciudades miembros, establecer vínculos, promover asociaciones; proporcionar el desarrollo de capacidades, y desarrollar instrumentos para alentar y reconocer los progresos realizados en la construcción de ciudades del aprendizaje.</p>
                        <a href="https://www.uil.unesco.org/es/learning-cities" rel='noopener noreferrer' target="_blank"> Ir al sitio web </a>   
                        </div>
                        <div className='aliados-section-box1 img'>
                            <img src={unesco} alt='logo baja ferries'/>
                        </div>                          
                    </div>
           
                 
                </div>


                

            </div>
        </section>
        <Footer />
    </>
  )
}
