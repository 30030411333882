import React, { useState } from 'react';
import '../css/popup.css'
import {  Outlet, } from 'react-router-dom';
import apexLogo from '../assets/images/logos/apex_APEX3.png'
export default function PopUp() {
    const [showPopup, setShowPopup] = useState(true);

    const togglePopup = () => {
      setShowPopup(!showPopup);
      console.log("showPopup", showPopup);
    };

  return (
    <>
    {showPopup && (

    <section className='popup'>
        <div className='container'>
            <div className='popup_content'>
                <button onClick={togglePopup}><i class="fi fi-bs-cross"></i></button>
                <div className='popup_content_box'>
                    <div>
                      <h2>El período de postulación ha finalizado.</h2>
                    </div>
                    <div className='popup_text'>                    
                      <p>Te invitamos a estar pendiente de nuestras próximas publicaciones sobre los <strong>resultados</strong>, <strong>clausura</strong> y entrega de<strong> reconocimientos</strong>. Si tienes alguna pregunta o deseas recibir actualizaciones, no dudes en ponerte en contacto con nosotros.</p>                                                         
                      <p>¡Gracias por tu interés y participación!</p>
                    </div> 
                    <div className='popup_logo'>
                      <img src={apexLogo} alt='apex logo' />
                    </div>
                </div>
            </div>
        </div>
    </section>
   
    )}
    
    <Outlet />
    </>
  )
}
