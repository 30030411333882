import React from 'react'
import '../css/aviso_privacidad.css'
import Footer from '../components/Footer'
export default function AvisoPrivacidad() {
  return (
    <>
    <section className='aviso_privacidad'>
       <div className='container'>
        <h2>AVISO DE PRIVACIDAD</h2>
        <div>
            <p><strong>BAJA FERRIES, S.A.P.I. de C.V.</strong>, con domicilio en calle Ignacio Allende, número 1025, esquina Marcelo Rubio, Colonia Centro, código postal 23000, La Paz, Baja California Sur, es el responsable del uso y protección de los datos personales, y al respecto informamos lo siguiente:  </p>
            <p>Se tratará la información y datos personales como confidenciales, y mantendrá medidas preventivas dirigidas a protegerla, no la divulgará para otro propósito que no sea el establecido en el presente Aviso de Privacidad, de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. </p>
            <h3>¿Para qué utilizaremos los datos personales?</h3>
            <p>Serán utilizados con la finalidad de realizar el registro a la convocatoria a la participación en el distintivo <strong>APEX La Paz</strong> (Acreditación para Pequeñas Empresas con Excelencia en Aprendizaje) de las Micro, Pequeñas y Medianas Empresas (MiPyMES) y Organizaciones de la Sociedad Civil (ONG´s).</p>
            <p>Los datos de contacto solicitados en la inscripción de las empresas y ONG’s, únicamente serán utilizados para la comunicación con los representantes de estas, así como para efectos del proceso de evaluación.</p>
            <p> El cual se realizará de manera digital ingresando a la página __________________________, que cuenta con todas las garantías de seguridad sobre la confidencialidad de la información de las organizaciones participantes, así como de los propios usuarios. </p>
            <h3>Para las finalidades antes señaladas, se solicitarán los siguientes datos personales:</h3>
            <ul>
                <li>NOMBRE COMPLETO</li>
                <li>RFC</li>
                <li>AÑO DE INICIO DE OPERACIONES</li>
                <li>FECHA DE NACIMIENTO </li>
                <li>CURP</li>
                <li>TIPO DE SECTOR </li>
                <li>SEXO</li>
                <li>EMAIL</li>
                <li>TELÉFONO</li>
                <li>DIRECCIÓN</li>
               
            </ul>
            <p>El nombre o marca comercial de las organizaciones que resulten ganadoras será publicado en la página web oficial así como en las redes sociales de las instituciones vinculadas al proyecto, con el fin de comunicar e inspirar a la comunidad empresarial a través de historias de éxito.</p>
            <h3>Transferencia de datos personales. </h3>
            <p>Se informa que no se realizará transferencias de datos personales adicionales, salvo aquellas que sea necesarias para atender los requerimientos de información de una autoridad competente, que estén debidamente fundados y motivados.
                Obtención de los datos personales. <br />Se obtienen a partir de la información que el titular nos proporcione de manera personal o bien, directamente del titular por cualquier medio electrónico, correo, página web o aplicaciones móviles (apps).
            </p>
            <h3>Derechos ARCO</h3>
            <p>Usted tiene derecho a conocer qué datos personales se tienen de usted, para qué se utilizan y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la ley (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
            <h3>Mecanismos para el ejercicio de los Derechos ARCO.</h3>
            <p>Los derechos antes descritos se ejercen a través de la presentación de la solicitud respectiva que por escrito y en idioma español debe presentar de manera gratuita en la dirección de Allende No. 1025, esquina Marcelo Rubio, Colonia Centro, C.P. 23000, La Paz, Baja California Sur o para mayor información comunicarse al teléfono: 6121270511, con atención al C.P. Sergio Pinzón Sopeña; o bien hacerla llegar por correo postal, previo pago del porte correspondiente, a la antes mencionada dirección y con atención a la misma persona.</p>
            <p>Para más información sobre la Seguridad de Información comunicarse al teléfono: 612 123 6600 ext. 1251 y 1252 El plazo para atender su solicitud será en un máximo de veinte días contados a partir de la fecha de recepción de su solicitud de acceso, rectificación, cancelación u oposición.</p>
            <p>Le hacemos patente que es el Instituto Federal de Acceso a la Información y Protección de Datos Personales quien tiene encomendado velar por sus derechos ARCO y el encargado de regular y verificar la observancia de la Ley. Derecho de revocar su consentimiento para el tratamiento de datos.</p>
            <p>En todo tiempo usted puede revocar el consentimiento que nos ha entregado para el tratamiento de sus datos personales con el propósito que se deje de hacer uso de estos, para ello es necesario que presente su petición según descrito en éste mismo aviso.</p>
            <p>Su solicitud deberá indicar nombre completo (nombre o nombres y apellido o apellidos) copia simple de su identificación oficial o, en medios electrónicos, versión digitalizada de la misma (escaneo), indicación del correo electrónico o dirección física que designe para notificaciones y algún número telefónico de contacto. En un plazo máximo de veinte días contados a partir de la fecha de recepción de su solicitud de revocación le daremos contestación sobre la procedencia de esta, por medio de correo electrónico dirigido a su persona, enviado a la dirección de correo electrónico que haya indicado en la propia solicitud o enviando carta al domicilio físico que haya indicado a tales efectos (los veinte días se darán por cumplidos al momento de entregar el documento al servicio postal).</p>
            <h3>Modificaciones al presente aviso de privacidad.</h3>
            <p>Nos queda reservado el derecho de efectuar, en cualquier tiempo, modificaciones o actualizaciones al presente aviso de privacidad. Las modificaciones que se efectúen se pondrán a disposición del público a través de algunos o todos los siguientes medios: anuncios visibles en nuestros establecimientos, vía nuestra página de Internet, vía correo electrónico a la dirección más reciente que tengamos de usted, por medio de publicaciones en periódicos de circulación nacional, revistas, carteles, grabaciones sonoras o personalmente por medio de nuestros encargados al momento de acudir a cualquiera de nuestras instalaciones.</p>
            <h3>Consentimiento tácito, en términos del artículo octavo de la Ley. </h3>
            <p>El Titular manifiesta que ha leído, entendido y aceptado los términos expuestos en el Aviso de Privacidad, lo que constituye el -consentimiento, libre, específico, inequívoco e informado-, inclusive con respecto a los cambios establecidos en las actualizaciones realizadas al mismo, con respecto al tratamiento de los Datos Personales en cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y los Lineamientos de la materia.</p>
        </div>

       </div>
    </section>
    <Footer />
    </>
  )
}

