import React, {useState, useEffect, useCallback} from 'react'
import '../css/dashboard.css'
import axios from 'axios';
import Navbar from '../components/Navbar'
import logo from '../assets/images/logos/apex_APEX5.png'
import {  useNavigate, Outlet, Link} from 'react-router-dom';
export default function Dashboard() {

    const [error, setError] = useState(''); // Para almacenar mensajes de error

    const [files, setFiles] = useState({
        file1: [],
        file1_status: false, 
        file2: [],
        file2_status: false, 
        file3: [],
        file3_status: false, 
        file4: [],   
        file4_status: false,       
    });
   
    const navigate = useNavigate();

    // Declaramos el estado y los hooks antes de cualquier condición
    const [tasks, setTasks] = useState([]);

    const userId = localStorage.getItem('userId');  // Recuperar usuario_id desde localStorage
    const loadTasks = useCallback(async () => {
       // const response = await fetch(`http://192.168.100.72:3091/user_capacitacion/${userId}`);
      const response = await fetch(`https://apexlpz.bajaferries.com.mx/user_capacitacion/${userId}`);
       //const response = await fetch(`http://localhost:3091/user_capacitacion/${userId}`);
        const data = await response.json();        
        setTasks(data);
        console.log(data.evidencia_estatus);

    }, [userId]);

    useEffect(() => {
        if (userId) {
            loadTasks();
        }
    }, [userId, loadTasks]);  // useEffect se ejecuta solo si userId existe

    // Si no hay userId, mostramos un mensaje alternativo pero no afectamos la ejecución de los hooks
    
    if (!userId) {
        return <div>Usuario no encontrado. Por favor, inicia sesión.</div>;
    }

     //Obtiene archivos 
     const handleFileChange = async (e) => {
        const { name, files } = e.target;

        // Verificar que todos los archivos sean PDFs
        const invalidFiles = Array.from(files).some(file => file.type !== 'application/pdf');
        if (invalidFiles) {
            setError('Solo se permiten archivos PDF');
            return;
        }

        // Limpiar mensaje de error si todos los archivos son válidos
        setError('');

        // Update form data
        setFiles(prevData => ({
            ...prevData,
            [name]: [...(prevData[name] || []), ...files], // Agregar archivos al arreglo existente
            [`file${name.slice(-1)}_status`]: true, // Actualizar el estado booleano correspondiente
        }));

        console.log("Files: ", files.name);


        // Subir los archivos inmediatamente
        if (files.length > 0) {
            const userId = localStorage.getItem('userId');
            await uploadFiles(userId, name, files);
        }

    };

     /* INICIO SUBIR IMAGEN */
     const uploadFiles = async (userId, name, files) => {
        const data = new FormData();
        
        console.log("userId: ", userId);

        data.append('userId', userId); // Add the user ID to the form data
        for (let i = 0; i < files.length; i++) {
          data.append(name, files[i]);
        }
    
        try {
            //const response = await axios.post('http://localhost:3091/api/documentacion', data, {
           const response = await axios.post('https://apexlpz.bajaferries.com.mx/api/documentacion', data, {
            
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('Respuesta del servidor:', response.data);
          
        } catch (error) {
          console.error('Error al enviar los datos:', error);
          
        }
    };

    // Mostrar los archivos subidos
    const renderFileNames = (fileList) => {
        return fileList.map((file, index) => (
            <div key={index}>
                <span>{file.name}</span> {/* Mostrar el nombre del archivo */}
            </div>
        ));
    };

    const uploadDcocuments = async (name) => {
        
        const data = new FormData();
    
        data.append('userId', userId); // Añadir el ID del usuario

        console.log("data1:", data);

  
        for (let i = 0; i < files.length; i++) {
            data.append(name);  // Agregar los archivos al FormData
            
        }

        console.log("data2:", data);
    /*
        try {
             const response = await axios.post('http://localhost:3091/documentacion', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            console.log('Archivos subidos correctamente:', response.data);
        } catch (error) {
            console.error('Error al subir archivos:', error);
        }*/
    };
    

  return (
    <>
    <Navbar />

    <section className='dashboard'>
        <div className='sidebar'>
            <ul>
                <li><Link to="/dashboard"><i class="fi fi-rr-home"></i> Dashboard</Link></li>                
                <li><i class="fi fi-rr-power"></i> Cerrar sesión</li>               
            </ul>
            <ul>
                <li>Documentación</li>
                <li><a href={`${process.env.PUBLIC_URL}/descargas/convocatoria-web.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-file-pdf"></i> Convocatoria</a> </li>    
                <li><a href={`${process.env.PUBLIC_URL}/descargas/bases-de-participacion.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-file-pdf"></i> Bases</a> </li>    
                <li><a href={`${process.env.PUBLIC_URL}/descargas/guia-apex.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-file-pdf"></i> Guía</a> </li>                  
            </ul>
            <ul>
                <li>Legal</li>
                <li><Link to="/aviso-de-privacidad"><i class="fi fi-rr-legal"></i> Aviso de privacidad</Link></li>                    
                <li><Link to="/terminos-y-condiciones-participacion"><i class="fi fi-rr-legal"></i>  Términos y condiciones de participación</Link></li>                      
                <li><Link to="/terminos-y-condiciones"><i class="fi fi-rr-legal"></i>  Términos y condiciones</Link></li>                                                     
            </ul>
            <ul>
                <li>¿Necesitas ayuda? </li>
                
                <li><a href="mailto:apex@bajaferries.com.mx"><i class="fi fi-rr-envelope"></i> Contactar</a></li>    
                
            </ul>
        </div>
        <div className='dashboard-space'>
            <div className=''>
                <div className='box_bienvenida'>
                    <div>
                        {/*<h2>Bienvenido a <strong>ApexLPZ</strong></h2>*/}
                        <h2>Bienvenido al panel de<br /> <strong>Administración</strong></h2>
                            <p>Aquí podrás verificar tu avance y completar tu postulación  </p>
                    </div>
                    <div className='box_bienvenida_img'>
                        <img src={logo} alt='logo apex blanco' />
                    </div>
               </div>
            </div>
           {/*} {tasks.evidencia_estatus ? (
                <div className='dashboard-content-capacitacion'>
                                   
                    <div className='dashboard-box-capacitacion noEdit' >
                        <label>Desarrollo Organizacional</label>
                        <label>{tasks.desarrollo_porcent_evidencia || '0'}%</label>
                        <label>{tasks.desarrollo_estatus || 'N/A'}</label>
                    </div>                                      
                    <div className='dashboard-box-capacitacion noEdit' >
                        <label>Sostenible</label>
                        <label>{tasks.sostenible_porcent_evidencia || '0'}%</label>
                        <label>{tasks.sostenible_estatus || 'N/A'}</label>
                    </div>
                    <div className='dashboard-box-capacitacion noEdit'>
                        <label>Nuevas Habilidades</label>
                        <label>{tasks.habilidades_porcent_evidencia || '0'}%</label>
                        <label>{tasks.habilidades_estatus || 'N/A'}</label>
                    </div>
                </div>
            ):(
            <div className='dashboard-content-capacitacion'>
                                   
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Desarrollo Organizacional</label>
                    <label>{tasks.desarrollo_porcent_evidencia || '0'}%</label>
                    <label>{tasks.desarrollo_estatus || 'N/A'}</label>
                </div>                                      
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Sostenible</label>
                    <label>{tasks.sostenible_porcent_evidencia || '0'}%</label>
                    <label>{tasks.sostenible_estatus || 'N/A'}</label>
                </div>
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Nuevas Habilidades</label>
                    <label>{tasks.habilidades_porcent_evidencia || '0'}%</label>
                    <label>{tasks.habilidades_estatus || 'N/A'}</label>
                </div>
            </div>
            )}*/}
            <div className='dashboard-content-capacitacion'>
                                   
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Desarrollo Organizacional</label>
                    <label>{tasks.desarrollo_porcent_evidencia || '0'}%</label>
                    <label>{tasks.desarrollo_estatus || 'N/A'}</label>
                </div>                                      
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Sostenible</label>
                    <label>{tasks.sostenible_porcent_evidencia || '0'}%</label>
                    <label>{tasks.sostenible_estatus || 'N/A'}</label>
                </div>
                <div className='dashboard-box-capacitacion' onClick={() => navigate(`/capacitacion/${userId}/edit`)} >
                    <label>Nuevas Habilidades</label>
                    <label>{tasks.habilidades_porcent_evidencia || '0'}%</label>
                    <label>{tasks.habilidades_estatus || 'N/A'}</label>
                </div>
            </div>

            <div className='documentacion-requerida'>
                <div className='content-documentacion-requerida'>
                    <div className='nota_documentacion'>
                        <h2>Sube tus documentos en formato PDF</h2>

                    </div>
                    {error &&(error)}
                    
                    {/*<div className='status_archivo'>
                        <div>
                            <label className='file-status'>{files.file1_status && ('Se ha subido correctamente ')}</label>
                            
                            <div className="file-list">{renderFileNames(files.file1)}</div>
                        </div>
                        <div>
                            <button onClick={uploadDcocuments}>Guardar</button>
                        </div>
                    </div>*/}
                    <label className='file-status'>{files.file1_status && ('Se ha subido correctamente ')}</label>
                    <div className='document-requerido'>
                        <label><i class="fi fi-rs-file-pdf"></i>Constancia de situación fiscal no mayor a tres meses. </label>
                        <label for="file-upload1" class="upload-button">
                            <i class="fi fi-sr-paperclip-vertical"></i> 
                        </label>
                        <input type="file" id="file-upload1" name="file1" onChange={handleFileChange}  accept="application/pdf" />                                    
                               
                    </div>                    
                    
                    {/*<div className='status_archivo'>
                        <div>
                            <label className='file-status'>{files.file2_status && ('Se ha subido correctamente ')}</label>                           
                            <div className="file-list">{renderFileNames(files.file2)}</div>
                        </div>
                        <div>
                            <button onClick={uploadDcocuments}>Guardar</button>
                        </div>
                    </div>*/}
                    <label className='file-status'>{files.file2_status && ('Se ha subido correctamente ')}</label> 
                    <div className='document-requerido'>
                        <label><i class="fi fi-rs-file-pdf"></i>Formato 32-D opinión de cumplimiento de obligaciones (positivo).</label>                        
                        <label for="file-upload2" class="upload-button">
                            <i class="fi fi-sr-paperclip-vertical"></i> 
                        </label>
                        <input type="file" id="file-upload2" name="file2" onChange={handleFileChange}  accept="application/pdf" />                                    
                               
                    </div>
                    {/*<div className='status_archivo'>
                        <div>
                            <label className='file-status'>{files.file3_status && ('Se ha subido correctamente ')}</label>                       
                            <div className="file-list">{renderFileNames(files.file3)}</div>
                        </div>
                        <div>
                            <button onClick={uploadDcocuments}>Guardar</button>
                        </div>
                    </div>*/}
                    <label className='file-status'>{files.file3_status && ('Se ha subido correctamente ')}</label>                 
                    <div className='document-requerido'>
                        
                        <label><i class="fi fi-rs-file-pdf"></i>INE del representante legal.</label>                        
                        <label for="file-upload3" class="upload-button">
                            <i class="fi fi-sr-paperclip-vertical"></i> 
                        </label>
                        <input type="file" id="file-upload3" name="file3" onChange={handleFileChange}  accept="application/pdf" />                                    
                               
                    </div>
                    {/*<div className='status_archivo'>
                        <div>
                            <label className='file-status'>{files.file4_status && ('Se ha subido correctamente ')}</label>                          
                        </div>
                        <div>
                            <button onClick={uploadDcocuments}>Guardar</button>
                        </div>
                    </div>*/}
                     <label className='file-status'>{files.file4_status && ('Se ha subido correctamente ')}</label>  
                    <div className='document-requerido'>
                        
                        <label><i class="fi fi-rs-file-pdf"></i>Comprobante de domicilio comercial en La Paz, B.C.S.</label> 
                        <label for="file-upload4" class="upload-button">
                            <i class="fi fi-sr-paperclip-vertical"></i> 
                        </label>
                        <input type="file" id="file-upload4" name="file4" onChange={handleFileChange}  accept="application/pdf" />                                                                   
                    </div>
                   
                </div>
                <div className='content-text-documentacion-requerida'>
                    <h2>Documentación para considerar el registro</h2>                    
                    <ul>
                        <li>Constancia de situación fiscal no mayor a tres meses.</li>
                        <li>Formato 32-D opinión de cumplimiento de obligaciones (positivo).</li>
                        <li>INE del representante legal.</li>
                        <li>Comprobante de domicilio comercial en La Paz, B.C.S.</li>
                    </ul>                  
                    <p><i>Nota: </i> Todos los documentos son requeridos (obligatorios.)</p>
                </div>
            </div>
            
        </div>        
    </section>


    
    <Outlet />
    
    </>
  )
}
