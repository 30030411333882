import React from 'react'
import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom'
import {useLayoutEffect} from 'react';
import Header from './components/Header';
import Body from './pages/Body'
import './css/tipografia.css'
import Registro from './pages/Registro'
import Login from './pages/Login'
import RegistroCompleto from './pages/RegistroCompleto';
import Capacitacion from './pages/Capacitacion'
import Capacitacion1 from './pages/Capacitacion1'
import Diagnostico from './pages/Diagnostico'
import PasswordReset from './pages/ResetPassword'
import Dashboard from './pages/Dashboard'
import DashboardAdmin from './pages/DashboardAdmin'
import Premios from './pages/Premios';
import ListUser from './pages/ListUser';
import ListONG from './pages/ListONG';
import ComiteEvaluador from './pages/ComiteEvaluador';
import AvisoPrivacidad from './pages/AvisoPrivacidad';
import TerminosCondiciones from './pages/TerminosCondiciones';
import TerminosCondicionesParticipacion from './pages/TerminosCondicionesParticipacion';
import Aliados from './pages/Aliados';
import Eliminar from './pages/Eliminar';
const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return children
}

function App() {
  
  return (
    <BrowserRouter>
        <Wrapper>
            <Routes>
                <Route path="/" element={<Header />}>
                    <Route index element={<Body />} />   
                    <Route path="registro" element={<Registro />} /> 
                    <Route path="login" element={<Login />} />         
                    <Route path="aliados" element={<Aliados />} />                                   
                    <Route path="registro-completo" element={<RegistroCompleto />} />     
                    <Route path="diagnostico" element={<Diagnostico />} />     
                    <Route path="capacitacion" element={<Capacitacion />} />    
                    <Route path="capacitacion1" element={<Capacitacion1 />} />    
                    <Route path="capacitacion/:id/edit" element={<Capacitacion />} />   
                    <Route path="capacitacion1/:id/edit" element={<Capacitacion1 />} />   
                    <Route path="premios-apex" element={<Premios />} />  
                    <Route path="password-reset" element={<PasswordReset />} />    
                    <Route path="comite-evaluador" element={<ComiteEvaluador />} />  
                    <Route path="aviso-de-privacidad" element={<AvisoPrivacidad />} /> 
                    <Route path="terminos-y-condiciones" element={<TerminosCondiciones />} /> 
                    <Route path="terminos-y-condiciones-participacion" element={<TerminosCondicionesParticipacion />} />
                    <Route path="eliminar" element={<Eliminar />} />     
                                
                </Route>   
                <Route path="dashboard" element={<Dashboard />} /> 
                <Route path="dashboard-admin" element={<DashboardAdmin />} />     
                <Route path="list-user" element={<ListUser />} />                             
                <Route path="mipyme" element={<ListUser />} /> 
                <Route path="ong" element={<ListONG />} /> 
            </Routes>
        </Wrapper>
      </BrowserRouter>
  );
}

export default App;