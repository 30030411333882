import React from 'react'
import { Outlet,  } from "react-router-dom";
import bases from '../assets/images/bases.webp'
import logoRojo from '../assets/images/logos/apex_APEX3.png';
import flechaV from '../assets/images/flechasV.png'
import flecha1 from '../assets/images/flechas1.png'
import flecha2 from '../assets/images/flechas2.png'
import flecha3 from '../assets/images/flechas3.png'
import flecha4 from '../assets/images/flechas4.png'
import download_bases from '../assets/images/download_bases.webp'
import video from '../assets/images/video.webp'
import download_guia from '../assets/images/download_guia.png'
import '../css/body.css'
import requisito from '../assets/images/requisito.webp'
import Hero from '../components/Hero'
import convocatoriaImg  from '../assets/images/convocatoria.png'

import convocatoria from '../assets/descargas/convocatoria-web.pdf'
import basesPDF from '../assets/descargas/bases-de-participacion.pdf'
import guia from '../assets/descargas/guia-apex.pdf'
import Aliados from '../components/Aliados';
import Footer from '../components/Footer';
export default function Body() {
  return (
    <>
    <Hero />
    <section className='bases'>
        <div className='container'>
            <div className='bases_container'>
                <div className='bases_content'>
                    <img src={bases} alt='bases de participacion apex' />
                </div>
                <div className='bases_content'>
                    
                    <h2>bases de participación</h2>
                    {/*} <p>El <strong>reconocimiento APEX</strong>, es una iniciativa impulsada por Baja Ferries en
                    coordinación con el Honorable Ayuntamiento de La Paz, La Red de Ciudades
                    del Aprendizaje y en colaboración con el Consejo Coordinador Empresarial
                    (CCE) de La Paz , Cámara Nacional de Comercio La Paz (CANACO), Confederación
                    Patronal de la República Mexicana (COPARMEX) La Paz BCS y el Centro de
                    Investigaciones Biológicas del Noroeste S.C. (CIBNOR) que reconoce a las <strong> MiPyMES y ONG’s</strong> del municipio de la paz comprometidas con la capacitación
                    y el desarrollo del aprendizaje significativo y constante de sus colaboradores</p>*/}
                    <p>La  <strong>Acreditación para Pequeñas Empresas con Excelencia en Aprendizaje</strong> (APEX), en su primera edición, busca reconocer, a partir del otorgamiento de un  distintivo y un estímulo en especie, a seis <strong>MiPyMES</strong> y dos <strong>ONG´s</strong> que destaquen en sus practicas de capacitación y desarrollo de colaboradores con compromiso hacia la sustentabilidad.
                    </p>
                    
                    <div className='line'>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='beneficios'>
        
        <div className='beneficios_container'>
            <div className='container'>
                <div className='beneficios_content'>
                    <h2>beneficios</h2>
                    <p>Reconocer la trayectoria y esfuerzo de las Micro, Pequeñas y Medianas Empresas (MiPyMES) y Organizaciones de la Sociedad Civil (ONG´s) por su compromiso con la formación y el desarrollo profesional y personal de sus colaboradores.</p>
                    <div>
                        <div className='beneficios_box'>        
                            <div className='num_box'>1</div>
                            <p>Distinción a nivel local y proyección a nivel estatal y nacional</p>
                        </div>
                        <div className='beneficios_box'>        
                            <div className='num_box'>2</div>
                            <p>Posicionamiento favorable en el mercado y la sociedad paceña y sudcaliforniana.</p>
                        </div>
                        <div className='beneficios_box'>        
                            <div className='num_box'>3</div>
                            <p>Recibir herramientas tecnologicas de información para fortalecer la operación.</p>
                        </div>
                        <div className='beneficios_box'>        
                            <div className='num_box'>4</div>
                            <p>Generación de identidad y cultura laboral dentro de la MiPyME y ONG, y así conservando colaboradores comprometidos con los objetivos de la organización.</p>
                        </div>
                    </div>

                </div>
                
            </div>
            <div className='beneficios_content'>
               {/*} <img src={beneficio} alt='beneficios apex' />*/}
            </div>

        </div>
    </section>
    <section className='requisitos'>
        <div className='container'>
            <div className='requisitos_box_title'>
                <h2>requisitos</h2>
                <p>Pueden postularse al distintivo las micro, pequeñas y medianas empresas y organizaciones de la sociedad civil que:</p>
            </div>
        </div>
        <div className='requisitos_box_img'>
            <img src={requisito} alt='requisitos apex' />            
        </div>
        <div className='container'>
            <div>
                {/*<div className='requisitos_box'>        
                    <div className='requisitos_box_disc'><span></span></div>
                    <p>No hayan sido objetivo de sanción por parte de alguna autoridad en materia administrativa, fiscal, laboral, ambiental o en cualquier otra de su jurisdicción en el año inmediato anterior al de esta convocatoria y durante el transcurso de su postulación.</p>
                </div*/}
                <div className='requisitos_box'>        
                    <div className='requisitos_box_disc'><span></span></div>
                    <p>Tengan operaciones en el municipio de La Paz, B.C.S.</p>
                </div>
                <div className='requisitos_box'>        
                    <div className='requisitos_box_disc'><span></span></div>
                    <p>Cumplan con los criterios y lineamientos de evaluación y selección de ganadores estipuladas en esta guía de participación.</p>
                </div>
                <div className='requisitos_box'>        
                    <div className='requisitos_box_disc'><span></span></div>
                    <p>Se encuentren legalmente constituidas.</p>
                </div>
            </div>
        </div>
    </section>
    <section className='categoria'>
        <div className='container'>
            <div className='categoria_container'>
                <div className='categoria_title'>
                    <h2>categorías</h2>
                    <p>La selección de las categorías de participación dependerá del tamaño de la organización, para las MIPYMES del sector al que estas pertenezcan y para las ONG’s según su objeto social.</p>
                </div>
                <div className='categorias_content'>
                    <div className='categoria_content'>
                        <h3>micro</h3>
                        <div className='categorias_box'>
                            <label>&lt; 10</label>
                            <span></span>
                            <label>Empleados</label>
                        </div>
                    </div>
                    <div className='categoria_content'>
                        <h3>pequeña</h3>
                        <div className='categorias_box'>
                            <label>11-50</label>
                            <span></span>
                            <label>Empleados</label>
                        </div>
                    </div>
                    <div className='categoria_content'>
                        <h3>mediana</h3>
                        <div className='categorias_box'>
                            <label>50-250</label>
                            <span></span>
                            <label>Empleados</label>
                        </div>
                    </div>
                </div>
                <div className='reconocimiento_content'>
                    <div className='reconocimiento_box'>
                        <h4>MiPyMES</h4>
                        <p>reconocimiento diferenciado por sector</p>
                        <ul>
                            <li>• <strong>Primario:</strong> Agricultura, Ganadería, pesca, acuacultura, entre otros.</li>
                            <li>• <strong>Secundario:</strong> Industrial (Empresas dedicadas a la manufactura o construcción, transformación de bienes)</li>
                            <li>• <strong>Terciario:</strong> Sector Servicios (comercio, transporte, servicios financieros, servicios sociales)</li>
                        </ul>
                    </div>
                    <div className='reconocimiento_box'>
                        <h4>ONG’s</h4>
                        <p>reconocimiento diferenciado objeto social</p>
                        <ul>                            
                            <li>• Innovación y educación</li>
                            <li>• Sostenibilidad y medio ambiente</li>                            
                        </ul>
                    </div>
                </div>
                <div className='nota'>
                    <p>El Consejo Evaluador podrá reclasificar a las organizaciones en otra categoría de participación a sugerencia, cuando su actividad o estructura así lo amerite. En tal caso, la organización participante será notificada sobre esta modificación y su fundamento.</p>
                </div>
            </div>

        </div>
        
    </section>
    <section className='inscripcion'>
        <div className='container'>
            <h2>proceso de postulación</h2> 
            <div className='inscripcion_content'>

                <div className='inscripcion_box'>
                    <div>
                        <label> </label>
                        <img src={logoRojo} alt='color verde' />
                    </div>
                    
                    <p> Ingresa a: apexlpz.bajaferries.com.mx </p>
                </div>
                <div className='inscripcion_box'>
                    <div>
                        <label>1</label>
                        <h2>Registro</h2>
                    </div>

                    <div className='flechas_body'>
                        <img  src={flechaV} alt='flecha'/>
                    </div>
                    <p> Crea un usuario empresarial y completa el diagnóstico</p>
                </div>
                <div className='inscripcion_box'>
                    <div>
                        <label>2</label>
                        <h2>confirmación</h2>
                    </div>
                    <div className='flechas_body'>
                        <img  src={flechaV} alt='flecha'/>
                    </div>
                    <p> Recibirás la confirmación de tu postulación </p>
                </div>
                <div className='inscripcion_box'>
                    <div>
                        <label> 3</label>
                        <h2>Cuestionario</h2>
                    </div>
                    <div className='flechas_body'>
                        <img  src={flechaV} alt='flecha'/>
                    </div>
                    <p> Responde el cuestionario junto con la carga de evidencias</p>
                </div>
                <div className='inscripcion_box'>
                    <div>
                        <label> 4</label>
                        <h2>Liberación</h2>
                    </div>
                    <div className='flechas_body'>
                        <img  src={flechaV} alt='flecha'/>
                    </div>
                    <p> Una vez que hayas completado el proceso de respuesta deberás de liberar el cuestionario</p>
                </div>
            </div>

        </div>
    </section>
    <section className='evaluacion'>
        <div className='container'>
            <h2>proceso de evaluación</h2> 
            <div className='evaluacion_content'>

                <div className='evaluacion_box'>
                    <div>
                        <label>Etapa 1</label>
                        <h2>Analisis de evidencias</h2>
                    </div>
                    <div className='flechas_body'>                    
                        <img  src={flecha1} alt='flecha'/>
                    </div>
                    <p>El consejo Evaluador revisará, analizará, y cotejará las evidencias presentadas que corresponda a la información solicitada. </p>
                </div>
                <div className='evaluacion_box'>
                    <div>
                        <label>Etapa 2</label>
                        <h2>Selección</h2>
                    </div>
                    <div className='flechas_body'>
                        <img  src={flecha2} alt='flecha'/>
                    </div>
                    <p> El consejo Evaluador identificará y seleccionará aquellas empresas (MiPymes y ONG’s) inscritas en la convocatoria que hayan cumplido satisfactoriamente con todos los requisitos. </p>
                </div>
                <div className='evaluacion_box'>
                    <div>
                        <label>Etapa 3</label>
                        <h2>Deliberación</h2>
                    </div>
                    <div className='flechas_body'>
                        <img  src={flecha3} alt='flecha'/>
                    </div>
                    <p>El consejo evaluador seleccionará los ganadores que destaquen por sus méritos en relación con los criterios establecidos. </p>
                </div>
                <div className='evaluacion_box'>
                    <div>
                        <label>Etapa 4</label>
                        <h2>Clausura</h2>
                    </div>
                    <div className='flechas_body'>
                        <img  src={flecha4} alt='flecha'/>
                    </div>
                    <p> 1. Anuncio de ganadores <br /> 2. Entrega de reconocimientos <br />  3. Socialización de participantes</p>
                </div>
            </div>

        </div>
    </section>

    <section className='descargas'>
        <div className='container'>
           <div className='descargas_content'>
                <div className='descargas_box'>
                    <div>
                        <img src={convocatoriaImg} alt=''/>
                    </div>
                    <div className='descargas_description'>
                        <label>Convocatoria</label>     
                        <div style={{display: 'flex'}}>
                            <label style={{marginRight: '1.3rem'}}><a href={convocatoria} download="convocatoria-web.pdf" ><i class="fi fi-rr-download"></i> </a> </label>
                            <label><a href={`${process.env.PUBLIC_URL}/descargas/convocatoria-web.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-eye"></i></a> </label>
                        </div>              
                        
                    </div>
                </div>
                <div className='descargas_box'>
                    <div>
                        <img src={download_bases} alt=''/>
                    </div>
                    <div className='descargas_description'>
                        <label>Bases</label>
                        <div style={{display: 'flex'}}> 
                            <label style={{marginRight: '1.3rem'}}><a href={basesPDF} download="bases-de-participacion.pdf" ><i class="fi fi-rr-download"></i> </a> </label>
                            <label><a href={`${process.env.PUBLIC_URL}/descargas/bases-de-participacion.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-eye"></i></a> </label>
                        </div>
                        
                    </div>
                </div>
                <div className='descargas_box'>
                    <div>
                        <img src={download_guia} alt=''/>
                    </div>
                    <div className='descargas_description'>
                        <label>Guía</label>
                        <div style={{display: 'flex'}}>
                            <label style={{marginRight: '1.3rem'}}><a href={guia} download="guia.pdf" ><i class="fi fi-rr-download"></i> </a> </label>
                            <label><a href={`${process.env.PUBLIC_URL}/descargas/guia-apex.pdf`}rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-eye"></i></a> </label>
                        </div>
                        
                    </div>
                </div>
                <div className='descargas_box' style={{opacity: '.5'}}>
                    <div>
                        <img src={video} alt=''/>
                    </div>
                    <div className='descargas_description'>
                        <label>Video</label>
                        <label><a href={`${process.env.PUBLIC_URL}/descargas/bases-de-participacion.pdf`} rel='noopener noreferrer' target="_blank"><i class="fi fi-rr-eye"></i></a> </label>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Aliados />
    <Footer />
    <Outlet />
    </>
  )
}


