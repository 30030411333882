import React from 'react'
import '../css/dashboard_admin.css'
import Navbar from '../components/NavbarAdmin'
import logo from '../assets/images/logos/apex_APEX5.png'
import { Link, Outlet  } from 'react-router-dom';
export default function DashboardAdmin() {
    const handleDownloadExcelmiPyme = () => {
        const link = document.createElement('a');
        //link.href = 'https://apexlpz.bajaferries.com.mx/download_excel_diagnostico_ong';
        link.href = 'http://localhost:3091/download_excel_diagnostico_ong';
        
        //const res = await fetch('https://apexlpz.bajaferries.com.mx/login', {
        link.setAttribute('download', 'Diagnostico_MIPYME.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    
    const handleDownloadExcelONG = () => {
        const link = document.createElement('a');
        link.href = 'http://localhost:3091/download_excel_diagnostico_ong';
        link.setAttribute('download', 'DiagnosticoONG.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    
  return (
    <>
    <Navbar />

    <section className='dashboard'>
        <div className='sidebar'>
            <ul>
                <li><Link to='/dashboard-admin'><i class="fi fi-rr-home"></i> Dashboard</Link></li>                
                <li><i class="fi fi-rr-power"></i> Cerrar sesión</li>
                
            </ul>
            <ul>                
                <li><i class="fi fi-rr-analyse-alt"></i> Analitycs</li>                                            
            </ul>
            <ul>
                <li>Documentación</li>
                <li><i class="fi fi-rr-file-pdf"></i> Convocatoria</li>    
                <li><i class="fi fi-rr-file-pdf"></i> Bases de participación</li>                            
                <li><i class="fi fi-rr-file-pdf"></i> Guía</li>                
            </ul>
            <ul>
                <li>Legal</li>
                <li><i class="fi fi-rr-legal"></i> Aviso de privacidad</li>    
                <li><i class="fi fi-rr-legal"></i> Términos y condiciones </li>                                                        
            </ul>
        </div>
        <div className='dashboard-space'>
          
            <div className=''>
               <div className='box_bienvenida'>
                    <div>
                        {/*<h2>Bienvenido a <strong>ApexLPZ</strong></h2>*/}
                        <h2>Bienvenido al panel de<br /> <strong>Administración</strong></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt <br />  ut labore et dolore magna aliqua.Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris.  </p>
                    </div>
                    <div className='box_bienvenida_img'>
                        <img src={logo} alt='logo apex blanco' />
                    </div>
               </div>
              
            </div>

            <div className=' lista-opciones-evidencias'>
                <div className='box-lista-opciones-evidencias'>
                    <Link to='/mipyme'> 
                    <div className='dashboard-admin-list-user'>
                        <h2>Usuarios registrados como <br /> <label>MIPYMES</label></h2>
                        <p>Aquí veras desplegada una lista con los usuarios registrados, <br />selecciona <strong>descargar</strong> para obtener la carpeta con la evidencia que el usuario ha enviado. </p>
                    </div>
                    </Link>
                </div>
                <div className='box-lista-opciones-evidencias'>
                    <Link to='/ong'> 
                    <div className='dashboard-admin-list-user'>
                        <h2>Usuarios registrados como <br /> <label>ONG´s</label> </h2>
                        <p>Aquí veras desplegada una lista con los usuarios registrados, <br />selecciona <strong>descargar</strong> para obtener la carpeta con la evidencia que el usuario ha enviado. </p>
                    </div>
                    </Link>
                </div>
            </div>
            <div className='lista-opciones-evidencias'>
                <div className='box-lista-opciones-evidencias'>
                    <div onClick={handleDownloadExcelmiPyme} > 
                    <div className='dashboard-admin-list-user'>
                        <h2>Descargar <br /> <label>Diagnostico MIPYMES</label></h2>
                        <p>Aquí veras desplegada una lista con los usuarios registrados, <br />selecciona <strong>descargar</strong> para obtener la carpeta con la evidencia que el usuario ha enviado. </p>
                    </div>
                    </div>
                </div>
                <div className='box-lista-opciones-evidencias'>
                    <div onClick={handleDownloadExcelONG} > 
                    <div className='dashboard-admin-list-user'>
                        <h2>Descargar <br /> <label>Diagnostico ONG´s</label></h2>
                        <p>Aquí veras desplegada una lista con los usuarios registrados, <br />selecciona <strong>descargar</strong> para obtener la carpeta con la evidencia que el usuario ha enviado. </p>
                    </div>
                    </div>
                </div>
            </div>

        </div>

        
       
    </section>

   
    
    <Outlet />
    </>
  )
}
