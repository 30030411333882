import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/login.css'
import logo from '../assets/images/logos/PAZ_Apex_Logotipo-01.png'

export default function ResetPassword() {
    const [credentials, setCredentials] = useState({
        correo: '',
        contrasena: ''
    });
    const [alert, setAlert] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("credentials: ", JSON.stringify(credentials)); 
        try {
            const res = await fetch('https://apexlpz.bajaferries.com.mx/resetpassword', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials)
            });

            const data = await res.json();

            if (res.ok) {
                console.log('Cambio de contraseña exitosa:', data);
                // Guardar el token de sesión en localStorage o manejar la sesión como necesites
                navigate('/dashboard'); // Redirigir al usuario a una página de destino
            } else {
                setAlert(true);
                console.error('Error:', data.message);
            }
        } catch (error) {            
            console.error('Error al enviar las credenciales:', error);
        }
    };

  return (
    <>
       <section className='login'>
        <div className='container'>
            <div className='form-content'>
          
                <form onSubmit={handleSubmit}>
                    <div className='login-box-logo'>
                        <img src={logo} alt='logo apex' class="logo"/>
                    </div>
                    <h2>Restablecer contraseña</h2>
                    <p>Ingresa tu correo y una nueva contraseña </p>
                    {alert && (
                                <>
                                    <label className='alertaMensaje'>El correo ingresado no está asociado a una cuenta existente. </label> <br />
                                </>
                            )}
                    <div className='input-group'>
                        <label>Correo</label>
                        <input type='email' name='correo' value={credentials.correo} onChange={handleChange}/>
                    </div>
                    <div className='input-group'>
                        <label>Nueva contraseña</label>
                        <input type='password' name='contrasena'  value={credentials.contrasena} onChange={handleChange}/>
                    </div>
 
                    <div className='input-group'>
                        <button type='submit' disabled={!credentials.correo || !credentials.contrasena}>
                            Enviar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
    </>
  )
}
